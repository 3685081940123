import { Component } from "react"
import { connect } from "react-redux"
import moment from "moment"
import classNames from "classnames"
import { withRouter } from "react-router-dom"

const mapStateToProps = ({ session }, ownProps) => {
  return {
    eventId: ownProps.match ? ownProps.match.params.id : "new",
    session,
  }
}

class ConferenceEventList extends Component {
  render() {
    const { conference, filteredSessions } = this.props

    return (
      <div className="conference-events-list">
        <ul>
          {filteredSessions.map((session) => {
            let userEvent = conference.conferenceUserEvents.data.find(
              (userEvent) =>
                userEvent.relationships.field_event.data.id === session.id
            )
            let rsvp = userEvent && userEvent.attributes.field_rsvp
            let formattedDate = moment(session.startDate).format(
              "dddd, MMMM Do, h:mma"
            )
            let fullRoom = conference.availableRooms.find(
              (room) => room.id === session.roomId
            )

            return (
              <li
                onClick={() => {
                  this.props.handleListClick(session, session)
                }}
                key={session.id}
                className="flexRow"
              >
                <div className="title">
                  <div
                    style={{
                      width: 15,
                      height: 15,
                      backgroundColor: session.color,
                      borderRadius: 15,
                      float: "left",
                      marginTop: 3,
                      marginRight: 8,
                    }}
                  />

                  <strong>{session.title}</strong>
                  <div className="dateTime">
                    <span className="date">{formattedDate}</span>
                  </div>
                </div>

                {fullRoom && (
                  <div
                    className="roomInfo flexRow"
                    style={{ justifyContent: "flex-end" }}
                  >
                    <span className="icon room color" />
                    <span>{fullRoom.attributes.name}</span>
                  </div>
                )}
                {rsvp && (
                  <div
                    className={classNames(
                      "rsvp-info",
                      rsvp && "hasRSVPd",
                      rsvp
                    )}
                  >
                    <span
                      className={classNames("icon", rsvp && "rsvp", rsvp)}
                    />
                  </div>
                )}
              </li>
            )
          })}
        </ul>
      </div>
    )
  }
}

export default withRouter(connect(mapStateToProps)(ConferenceEventList))
