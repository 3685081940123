import { Component } from "react"

import { connect } from "react-redux"
import { Grid } from "@mui/material"

import { changeNavbar, deleteAssessment } from "../../actions"
import {
  getCourses,
  getAssessments,
} from "@mobilemind/common/src/actions/course"
import PropTypes from "prop-types"

import ViewToggle from "../../components/ViewToggle"
import CourseCard from "../../components/CourseCard"
import CourseListItem from "../../components/CourseListItem"
import Loading from "@mobilemind/common/src/components/Loading"

import theme from "@mobilemind/common/src/theme/theme"
import _ from "lodash"

const mapStateToProps = (
  { inProgress, session, categories, viewStyle, sidebar },
  ownProps
) => {
  let currentTab = sidebar.pages.inProgress.activeItem

  let courses
  if (currentTab === "feedback") {
    courses = inProgress.inProgress.filter(
      (course) => course.field_user_assessment_comment
    )
  } else if (currentTab === "in_progress") {
    courses = inProgress.inProgress.filter(
      (course) => !course.field_user_assessment_comment
    )
  } else if (currentTab === "saved_for_later") {
    courses = inProgress.saved.filter(
      (course) => course.field_user_assessment_status === "saved_for_later"
    )
  } else if (currentTab === "submitted") {
    courses = inProgress.submitted
  }

  return {
    currentTab,
    session,
    categories,
    courses: _.orderBy(courses, "field_user_assessment_last_updated", "desc"),
    viewStyle,
  }
}

const mapDispatchToProps = {
  deleteAssessment,
  getAssessments,
  getCourses,
  changeNavbar,
}

function InProgress(props) {
  return <InProgressContent {...props} theme={theme} />
}

class InProgressContent extends Component {
  state = {
    fetched: false,
  }

  async componentDidMount() {
    this.props.changeNavbar("in-progress")
  }

  removeAssessment = (id) => {
    this.props.deleteAssessment(id, this.props.session)
  }

  render() {
    const { currentTab, fetched, courses, categories, viewStyle, theme } =
      this.props

    let content, statusText
    switch (currentTab) {
      case "saved_for_later":
        statusText = (
          <>
            You don't have any micro-courses saved. Click{" "}
            <strong>Save for Later</strong> when viewing a micro-course to pin
            it here.
          </>
        )
        break
      case "feedback":
        statusText = <>No feedback yet. Submit a challenge!</>
        break
      case "submitted":
        statusText = <>You haven't submitted any challenges yet. Let's go!</>
        break
      default:
        statusText = (
          <>You don't have any outstanding challenges — time to get started!</>
        )
    }

    if (courses) {
      content = (
        <>
          {courses.length > 0 &&
            courses.map((course, index) => {
              const category = categories.data.find(
                (cat) =>
                  cat.attributes.drupal_internal__tid ===
                  Number(course.field_category_target_id)
              )

              if (viewStyle === "list") {
                return (
                  <CourseListItem
                    animationDelay={index * 0.05 + "s"}
                    key={course.uuid + index}
                    course={course}
                    assessmentId={course.field_user_assessment_uuid}
                    category={category}
                    status={course.field_user_assessment_status}
                    currentPage={currentTab}
                    removeAssessment={this.removeAssessment}
                  />
                )
              }

              return (
                <CourseCard
                  animationDelay={index * 0.05 + "s"}
                  key={course.uuid + index}
                  course={course}
                  assessmentId={course.field_user_assessment_uuid}
                  category={category}
                  status={course.field_user_assessment_status}
                  currentPage={currentTab}
                  removeAssessment={this.removeAssessment}
                />
              )
            })}
        </>
      )
    }

    return (
      <div className="page in-progress">
        {fetched ? (
          <>
            {courses.length > 0 && (
              <>
                <ViewToggle />
                {currentTab === "submitted" && (
                  <p className="statusText submitted">
                    Please allow 24-48 hours for challenge submissions to be
                    reviewed.
                  </p>
                )}
              </>
            )}

            {courses.length > 0 && (
              <>
                {viewStyle === "grid" ? (
                  <div className="course-library course-grid">
                    <Grid container spacing={3}>
                      {content}
                    </Grid>
                  </div>
                ) : (
                  <ul className="course-library course-list">{content}</ul>
                )}
              </>
            )}

            {!courses.length && <p className="statusText">{statusText}</p>}
          </>
        ) : (
          <Loading
            color={theme.palette.secondary.main}
            message="Getting your courses..."
          />
        )}
      </div>
    )
  }
}

InProgress.propTypes = {
  categories: PropTypes.object,
  courses: PropTypes.array,
  currentPage: PropTypes.string,
  routeParams: PropTypes.object,
  session: PropTypes.object,
  viewStyle: PropTypes.string,
}

export default connect(mapStateToProps, mapDispatchToProps)(InProgress)
