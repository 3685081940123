import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import fetchWrapper from "@mobilemind/common/src/functions/fetchWrapper"
import qs from "qs"
import _ from "lodash"
import moment from "moment"
import he from "he"
import { RootState } from "../store"
import debounceThunk from "@mobilemind/common/src/functions/debounceThunk"
import { UTCToLocalTime } from "@mobilemind/common/src/functions"
import { setDSTDisplay } from "@mobilemind/common/src/functions"

// @todo -- this is used on the Dashboard (which we will ultimately replace with a view)
// AND on a single event page. For the latter we should fetch just a single user event tied to the event we're loading
export const fetchUserEvents = createAsyncThunk<
  any,
  void,
  { state: RootState }
>("calendarSlice/fetchUserEvents", async (args, thunkAPI) => {
  let pages = 1
  let conferencePages = 1
  let i = 0
  let userEvents: any[] = []
  let response

  type Query = {
    page: {
      offset: number
    }
    filter: any
  }

  let query: Query = {
    page: {
      offset: 0,
    },
    filter: {
      "field_user.id": thunkAPI.getState().session.user.id,
    },
  }

  /**
   * We'll need to get both bundles of user events, vanilla and conference, but they both can have
   * different numbers of pages, so we'll run our loop twice
   */

  // Get normal user events
  while (i < pages) {
    query.page = { offset: i * 50 }

    response = await fetchWrapper.get(
      "/api/mobile_mind_user_event/user_event_base?" + qs.stringify(query)
    )
    if (response.ok) {
      let data = await response.json()
      pages = Math.ceil(Number(data.meta.count) / 50)
      userEvents = userEvents.concat(data.data)
    }

    i++
  }

  // And conference session user events
  i = 0
  while (i < conferencePages) {
    query.page = { offset: i * 50 }

    response = await fetchWrapper.get(
      "/api/mobile_mind_user_event/conference?" + qs.stringify(query)
    )

    if (response.ok) {
      let data = await response.json()
      conferencePages = Math.ceil(Number(data.meta.count) / 50)
      data.data.forEach((data: any) => (data.isConference = true))
      userEvents = userEvents.concat(data.data)
    }

    i++
  }

  return userEvents
})

export const fetchSingleUserEvent = createAsyncThunk<
  any,
  any,
  { state: RootState }
>("calendarSlice/fetchSingleUserEvent", async (args, thunkAPI) => {
  const { uuid, bundle, eventId } = args
  const { session } = thunkAPI.getState()

  let query = {
    filter: {
      "field_user.id": session.user.id,
      "field_event.id": uuid,
    },
  }
  let requestBundle = bundle === "event_base" ? "user_event_base" : bundle

  let response = await fetchWrapper.get(
    "/api/mobile_mind_user_event/" + requestBundle + "?" + qs.stringify(query)
  )
  if (response.ok) {
    let data = await response.json()

    if (data.data[0]) {
      return data.data[0]
    }
    // If they don't have a user event we can create one for them
    else {
      let body = {
        data: {
          type: "mobile_mind_user_event--" + requestBundle,
          attributes: {
            name:
              "Invite for User: " +
              session.user.attributes.drupal_internal__uid +
              " " +
              "Event: " +
              eventId,
          },
          relationships: {
            field_event: {
              data: {
                id: uuid,
                type: "mobilemind_event_entity--" + bundle,
              },
            },
            field_user: {
              data: {
                id: session.user.id,
                type: "user--user",
              },
            },
          },
        },
      }

      response = await fetchWrapper.post(
        "/api/mobile_mind_user_event/" + requestBundle,
        session.token,
        JSON.stringify(body)
      )
      if (response.ok) {
        let data = await response.json()
        return data.data
      }
    }
  }
})

export const fetchCalendar = createAsyncThunk<any, any, { state: RootState }>(
  "calendarSlice/fetchCalendar",
  async (args, thunkAPI) => {
    const { locations, session } = thunkAPI.getState()
    const { dateRange } = args

    let data: {
      rows: any[]
    } = {
      rows: [],
    }

    let goals: any[] = []

    let query = {
      event_date: dateRange,
    }

    let response = await fetchWrapper.get(
      "/api/events-calendar-learn?" + qs.stringify(query)
    )

    if (response.ok) {
      data = await response.json()

      let finalData = {
        rows: [],
      }
      if (data.rows.length) {
        data.rows.forEach((event: any) => {
          event.name = he.decode(event.name)
          event.location = locations.data.filter((location: any) => {
            return event.field_location.includes(
              location.attributes.drupal_internal__id
            )
          })

          // This is necessary for multiple location conferences which will be returned
          // however many times they have locations
          if (
            !finalData.rows.find((existing: any) => existing.id === event.id)
          ) {
            // @ts-ignore
            finalData.rows.push(event)
          }
          data = finalData
        })
      } else {
        data = { rows: [] }
      }

      response = await fetchWrapper.get(
        "/api/user-goal-calendar?" + qs.stringify(query)
      )

      if (response.ok) {
        goals = await response.json()
        if (!goals.length) {
          goals = []
        } else {
          goals.forEach((goal: any) => {
            goal.lp_name = he.decode(goal.lp_name)
          })
        }

        const query = {
          start: dateRange.min,
          end: dateRange.max,
        }
        response = await fetchWrapper.get(
          "/api/mm_rec_ext_event/learn?" + qs.stringify(query)
        )

        if (response.ok) {
          let recommended = await response.json()

          const keys = Object.keys(recommended.rec_external_event_data)
          const recommendedData = keys.map(
            (key) => recommended.rec_external_event_data[key]
          )

          // Gotta set these times for local timezone
          recommendedData.forEach((event) => {
            if (event.field_start_date_value && event.field_end_date_value) {
              event.field_start_date_value = UTCToLocalTime(
                event.field_start_date_value + "-00:00",
                session.user.attributes.timezone,
                "yyyy-MM-dd'T'HH:mm:ss"
              )

              event.field_end_date_value = UTCToLocalTime(
                event.field_end_date_value + "-00:00",
                session.user.attributes.timezone,
                "yyyy-MM-dd'T'HH:mm:ss"
              )

              if (
                !moment().isDST() &&
                moment(event.field_start_date_value).isDST()
              ) {
                event.field_start_date_value = moment(
                  event.field_start_date_value
                )
                  .add(1, "hour")
                  .format()
                event.field_end_date_value = moment(event.field_end_date_value)
                  .add(1, "hour")
                  .format()
              }
            }
          })

          return { data, goals, recommended: recommendedData }
        }
      }
    }
  }
)
export const debouncedFetchCalendar = debounceThunk(fetchCalendar, 750)

type InitialState = {
  sidebarActiveItem: string
  fetched: boolean
  data: any[]
  onlyMyEvents: boolean
  isOutlookModalOpen: boolean
  isGCalModalOpen: boolean
  integrationAuthSuccess: boolean
  isAuthenticationInProgress: boolean
  isFetchingMore: boolean
  searchQuery: string
  truncatedData: any[]
  selectedTags: any[]
  currentDate: string
  recommendedEvents: {
    fetched: boolean
    data: any[]
  }
  userEvents: {
    fetched: boolean
    data: any[]
  }
  googleEvents: {
    fetched: boolean
    data: any[]
  }
  categories: {
    fetched: boolean
    data: any[]
  }
}

const initialState: InitialState = {
  sidebarActiveItem: "Event Calendar",
  fetched: false,
  data: [],
  onlyMyEvents: false,
  isOutlookModalOpen: false,
  isGCalModalOpen: false,
  integrationAuthSuccess: false,
  isAuthenticationInProgress: false,
  isFetchingMore: true,
  searchQuery: "",
  truncatedData: [],
  selectedTags: [],
  currentDate: moment().format("YYYY-MM-DD"),
  recommendedEvents: {
    fetched: false,
    data: [],
  },
  userEvents: {
    fetched: false,
    data: [],
  },
  googleEvents: {
    fetched: false,
    data: [],
  },
  categories: {
    fetched: false,
    data: [],
  },
}

export const calendar = createSlice({
  name: "calendarSlice",
  initialState,
  reducers: {
    setCurrentDate: (state, action) => {
      state.currentDate = action.payload
    },
    setSidebarItem: (state, action) => {
      state.sidebarActiveItem = action.payload
    },
    setSearchQuery: (state, action) => {
      state.searchQuery = action.payload
    },
    setTags: (state, action) => {
      const { tag, method } = action.payload
      if (method === "add") {
        state.selectedTags.push(tag)
      } else {
        state.selectedTags.splice(action.payload.value, 1)
      }
    },
    toggleOnlyMy: (state, action) => {
      state.onlyMyEvents = action.payload
    },
    updateRSVP: (state, action) => {
      state.userEvents.data = state.userEvents.data.filter(
        (userEvent) => userEvent.id !== action.payload.id
      )
      state.userEvents.data.push(action.payload)
    },
    setIsGCalModalOpen: (state, action) => {
      state.isGCalModalOpen = action.payload
    },
    setIsOutlookModalOpen: (state, action) => {
      state.isOutlookModalOpen = action.payload
    },
    addedToGoogle: (state, action) => {
      state.googleEvents.data.push(action.payload)
    },
    setIntegrationAuthSuccess: (state, action) => {
      state.integrationAuthSuccess = action.payload
    },
    setAuthenticationInProgress: (state, action) => {
      state.isAuthenticationInProgress = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchCalendar.pending, (state, action) => {
      state.fetched = false

      if (!action.meta.arg.isFetchingMore) {
        state.data = []
        state.fetched = false
      } else {
        state.isFetchingMore = true
      }
    })

    builder.addCase(fetchCalendar.fulfilled, (state, action) => {
      state.isFetchingMore = false

      if (
        moment(state.currentDate).format("MM") !==
        moment(action.meta.arg.dateRange.min).format("MM")
      ) {
        const events =
          action.payload && action.payload.data.rows.length
            ? action.payload.data.rows
                .map((event: any) => {
                  let startDate = event.field_event_date_time_value
                  let endDate = event.field_event_date_time_end_value

                  if (event.field_all_day === "True") {
                    startDate = moment(event.field_event_date_time_value)
                      .startOf("day")
                      .format()
                    endDate = moment(event.field_event_date_time_value)
                      .add(1, "day")
                      .startOf("day")
                      .format()
                  } else {
                    startDate = moment(setDSTDisplay(startDate)).format()
                    endDate = moment(setDSTDisplay(endDate)).format()

                    if (moment(startDate).isDST() && !moment().isDST()) {
                      startDate = moment(startDate).add(1, "hour").format()
                      endDate = moment(endDate).add(1, "hour").format()
                    } else if (!moment(startDate).isDST() && moment().isDST()) {
                      startDate = moment(startDate).subtract(1, "hour").format()
                      endDate = moment(endDate).subtract(1, "hour").format()
                    }
                  }

                  let eventObject = {
                    isAllDay: event.field_all_day,
                    isConference: Boolean(event.type === "conference"),
                    isObservation: Boolean(event.type === "observation"),
                    rsvp: event.field_rsvp,
                    image: event.field_event_image,
                    drupal_internal__id: event.id,
                    startDate,
                    endDate,
                    field_tags: event.field_tags,
                    title: event.name,
                    description: event.field_description,
                    location: event.location,
                  }

                  return eventObject
                })
                .filter((event: any) => {
                  return !state.data.find(
                    (existing) =>
                      existing.drupal_internal__id === event.drupal_internal__id
                  )
                })
            : []

        if (!events.length) {
          const monthsSpan = moment
            .duration(
              moment(action.meta.arg.dateRange.max)
                .add(2, "days")
                .diff(moment(action.meta.arg.dateRange.min))
            )
            .months()
          for (let i = 0; i < monthsSpan; i++) {
            events.push({
              drupal_internal__id: moment(action.meta.arg.dateRange.min)
                .add(i, "months")
                .format("YYYY-MM-DD"),
              isEmptyMonth: true,
              startDate: moment(action.meta.arg.dateRange.min)
                .add(i, "months")
                .format("YYYY-MM-DD"),
              month: moment(action.meta.arg.dateRange.min)
                .add(i, "months")
                .format("YYYY-MM-DD"),
            })
          }

          if (!monthsSpan && action.meta.arg.isFetchingMore) {
            events.push({
              drupal_internal__id: moment(action.meta.arg.dateRange.min)
                .add(0, "months")
                .format("YYYY-MM-DD"),
              isEmptyMonth: true,
              startDate: moment(action.meta.arg.dateRange.min)
                .add(0, "months")
                .format("YYYY-MM-DD"),
              month: moment(action.meta.arg.dateRange.min)
                .add(0, "months")
                .format("YYYY-MM-DD"),
            })
          }
        }

        const goals =
          action.payload &&
          action.payload.goals
            .map((goal: any) => {
              let eventObject = {
                id: goal.uuid,
                lp_name: goal.lp_name,
                isGoal: true,
                drupal_internal__id: goal.id,
                image: goal.field_image,
                isAllDay: "True",
                isConference: false,
                startDate: moment(goal.field_goal_date).startOf("day").format(),
                endDate: moment(goal.field_goal_date)
                  .add(1, "day")
                  .startOf("day")
                  .format(),
                title: goal.lp_name,
              }

              return eventObject
            })
            .filter((goal: any) => {
              return !state.data.find(
                (existing) =>
                  existing.drupal_internal__id === goal.drupal_internal__id
              )
            })

        state.recommendedEvents.data = action.payload.recommended
          ? action.payload.recommended
          : []
        state.recommendedEvents.fetched = true

        const recommendedEvents =
          action.payload &&
          action.payload.recommended &&
          action.payload.recommended.map((event: any) => {
            let startDate = event.field_start_date_value
            let endDate = event.field_end_date_value

            if (moment(startDate).isDST() && !moment().isDST()) {
              startDate = moment(startDate).subtract(1, "hour").format()
              endDate = moment(endDate).subtract(1, "hour").format()
            }

            let eventObject = {
              ext_usr_event_status: event.ext_usr_event_status,
              isExternal: true,
              image:
                event.image &&
                event.image.replace(process.env.REACT_APP_API_URL, ""),
              startDate,
              endDate,
              title: event.title,
              description: event.description__value,
              fullEvent: event,
              uuid: event.uuid,
            }

            return eventObject
          })

        if (action.meta.arg.isFetchingMore) {
          state.data = state.data
            .concat(
              _.orderBy(
                events.filter((event: any) => {
                  // Make sure we're not bringing in duplicates
                  return !state.data.find(
                    (existing) =>
                      existing.drupal_internal__id === event.drupal_internal__id
                  )
                }),
                (event: any) => event.startDate
              )
            )
            .concat(goals)
            .concat(recommendedEvents)
        } else {
          state.data = _.orderBy(events, (event: any) => event.startDate)
            .concat(goals)
            .concat(recommendedEvents)
        }

        let truncatedData: any[] = []

        state.data.forEach((event) => {
          if (event) {
            let eventsOnDay = truncatedData.filter((existing) => {
              let onDay =
                moment(existing.startDate).startOf("day").format("MM/DD") ===
                moment(event.startDate).startOf("day").format("MM/DD")

              if (
                moment(event.startDate).isBetween(
                  existing.startDate,
                  existing.endDate
                )
              ) {
                onDay = true
              }

              return onDay
            })

            if (eventsOnDay.length < 2) {
              truncatedData.push(event)
            }
          }
        })

        state.data = state.data.filter((event) => {
          return event !== undefined
        })

        state.truncatedData = truncatedData
        state.fetched = true
      }
    })

    builder.addCase(fetchUserEvents.fulfilled, (state, action) => {
      state.userEvents.data = action.payload
      state.userEvents.fetched = true
    })

    builder.addCase(fetchSingleUserEvent.fulfilled, (state, action) => {
      if (action.payload) {
        state.userEvents.data = state.userEvents.data.filter(
          (event) => event.id !== action.payload.id
        )

        state.userEvents.data.push(action.payload)
      }
    })
  },
})

export const {
  toggleOnlyMy,
  setIsGCalModalOpen,
  setIsOutlookModalOpen,
  setCurrentDate,
  setTags,
  setSearchQuery,
  setAuthenticationInProgress,
  setIntegrationAuthSuccess,
  setSidebarItem,
} = calendar.actions

export default calendar.reducer
