import fetchWrapper from "../functions/fetchWrapper"
import qs from "qs"
import moment from "moment"

export function deleteNotification(notification) {
  return async (dispatch, getState) => {
    try {
      await fetchWrapper.remove(
        "/api/notification_entity/notification_entity/" + notification.id,
        getState().session.token
      )
    } catch (err) {
      console.log(err)
    }
  }
}

export function getUnlockedPaths(notification) {
  return async () => {
    try {
      let query = {
        filter: {
          "field_prerequisite_learning_path.id":
            notification.learning_path_uuid,
        },
      }

      let response = await fetchWrapper.get(
        "/api/learning_path/learning_path?" + qs.stringify(query)
      )

      if (response.ok) {
        let data = await response.json()
        return data
      }
    } catch (err) {
      console.log(err)
    }
  }
}

/**
 * PATCH a notification entity's field_notification_seen field so it doesn't appear as unread
 * @param {string} id The id of the notification entity to patch
 */
export function markNotificationAsSeen(id) {
  return async (dispatch, getState) => {
    let body = JSON.stringify({
      data: {
        id: id,
        type: "notification_entity--notification_entity",
        attributes: {
          field_notification_seen: true,
        },
      },
    })

    try {
      await fetchWrapper.patch(
        "/api/notification_entity/notification_entity/" + id,
        getState().session.token,
        body
      )
    } catch (err) {
      console.log(err)
    }
  }
}

export function getNotifications() {
  return async (dispatch) => {
    try {
      let response = await fetchWrapper.get("/api/user-notification/list?")
      if (response.ok) {
        let data = await response.json()

        dispatch({
          type: "notifications/get",
          payload: data.data.items,
        })
      }
    } catch (err) {
      console.log(err)
    }
  }
}

export function setNotificationsFetched(user) {
  return async (dispatch, getState) => {
    let body = JSON.stringify({
      data: {
        id: user.id,
        type: "user--user",
        attributes: {
          field_notifications_last_fetched: new moment().format(),
        },
      },
    })

    try {
      await fetchWrapper.patch(
        "/api/user/user/" + user.id,
        getState().session.token,
        body
      )
    } catch (err) {
      console.log(err)
      dispatch({
        type: "snackbar/update",
        payload: err.message,
      })
    }
  }
}
