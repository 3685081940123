import qs from "qs"
import fetchWrapper from "../functions/fetchWrapper"
import moment from "moment"

export function getUserFeedbackForm(args) {
  return async (dispatch, getState) => {
    let query = {
      filter: {
        "uid.id": getState().session.user.id,
        "field_event.id": args.id,
      },
      include: "field_responses",
    }

    let response = await fetchWrapper.get(
      "/api/mm_user_form/event_feedback?" + qs.stringify(query)
    )
    if (response.ok) {
      let data = await response.json()

      if (data.data[0]) {
        data.data[0].responses = data.included
      }
      return data.data[0]
    }
  }
}
export function createUserFeedbackForm(args) {
  return async (dispatch, getState) => {
    const { activeEvent, session } = getState()
    const token = args ? session.mobileToken.csrf_token : session.token

    // Learn app will pass no arguments
    const eventId = args ? args.passedEvent.id : activeEvent.drupal_internal__id
    const eventUUID = args ? args.passedEvent.event_uuid : activeEvent.id
    const bundle = args ? args.activeEvent.mm_event_type : activeEvent.bundle
    const feedbackFormUUID = args
      ? args.activeEvent.field_feedback_form.uuid
      : activeEvent.feedbackForm.id
    const formQuestions = args
      ? args.responseData
      : activeEvent.feedbackForm.questions

    // First post the form and tie it to the event and the user
    const initialFormBody = {
      data: {
        type: "mm_user_form--event_feedback",
        attributes: {
          label:
            "Event: " +
            eventId +
            " / User: " +
            session.user.attributes.drupal_internal__uid,
          field_date_and_time: moment().format(),
        },
        relationships: {
          field_form: {
            data: {
              type: "mm_form--event_feedback",
              id: feedbackFormUUID,
            },
          },
          field_event: {
            data: {
              id: eventUUID,
              type: "mobilemind_event_entity--" + bundle,
            },
          },
        },
      },
    }

    // If the feedback form (via the addon) does NOT have anonymous feedback turned on,
    // add field_user to the user form
    if (
      (!args && !activeEvent.addOn.attributes.field_anonymous_feedback) ||
      (args && !args.activeEvent.field_anonymous_feedback)
    ) {
      initialFormBody.data.relationships.field_user = {
        data: {
          type: "user--user",
          id: session.user.id,
        },
      }
    }

    let postResponse = await fetchWrapper.post(
      "/api/mm_user_form/event_feedback",
      token,
      JSON.stringify(initialFormBody)
    )

    if (postResponse.ok) {
      // Our created form
      let createdFormData = await postResponse.json()

      // We'll collect the uuids of our response paragraphs here
      const field_responses_data = []

      let i = 0

      // Go through all the questions
      while (i < formQuestions.length) {
        const question = formQuestions[i]
        const paragraphId = question.attributes
          ? question.attributes.drupal_internal__id
          : question.id

        const paragraphBody = {
          data: {
            type: "paragraph--form_responses",
            attributes: {
              parent_id: createdFormData.data.attributes.drupal_internal__id,
              parent_type: "mm_user_form",
              parent_field_name: "field_responses",
              field_paragraph_id: paragraphId,
            },
          },
        }
        if (question.type.includes("open_ended_questions")) {
          paragraphBody.data.attributes.field_open_ended = question.userResponse
        }
        if (question.type.includes("likert_scale")) {
          paragraphBody.data.attributes.field_likert_answer =
            question.userResponse
        }

        // Post a new paragraph
        let paragraphResponse = await fetchWrapper.post(
          "/api/paragraph/form_responses",
          token,
          JSON.stringify(paragraphBody)
        )

        if (paragraphResponse.ok) {
          const postedParagraph = await paragraphResponse.json()
          // Throw it in the array
          field_responses_data.push({
            type: "paragraph--form_responses",
            id: postedParagraph.data.id,
            meta: {
              target_revision_id:
                postedParagraph.data.attributes.drupal_internal__id,
              drupal_internal__target_id:
                postedParagraph.data.attributes.drupal_internal__id,
            },
          })
        }

        i++
      }

      // Attach all those response paragraphs to the form
      const formPatchBody = {
        data: {
          type: "mm_user_form--event_feedback",
          id: createdFormData.data.id,
          relationships: {
            field_responses: {
              data: field_responses_data,
            },
          },
        },
      }

      // And patch it
      let finalResponse = await fetchWrapper.patch(
        "/api/mm_user_form/event_feedback/" + createdFormData.data.id,
        token,
        JSON.stringify(formPatchBody)
      )
      let finalResponseData = await finalResponse.json()
      return finalResponseData.data
    }
  }
}
