import { Component } from "react"
import { connect } from "react-redux"

import {
  Paper,
  Checkbox,
  FormControl,
  TextField,
  Select,
  MenuItem,
  FormControlLabel,
} from "@mui/material"
import { ViewState } from "@devexpress/dx-react-scheduler"

import classNames from "classnames"
import moment from "moment"
import { changeNavbar } from "../../../actions"

import EventTooltip from "./EventTooltip"

import { claimRecommended } from "../../../store/reducers/externalPD"

import {
  debouncedFetchCalendar,
  toggleOnlyMy,
  setSearchQuery,
  setCurrentDate,
  setTags,
} from "../../../store/reducers/calendar"

import Values from "values.js"

import { getLocations } from "../../locations/locationsSlice"
import theme from "@mobilemind/common/src/theme/theme"

import "../../../styles/calendar.scss"
import TagSelect from "@mobilemind/common/src/components/TagSelect"

import {
  Scheduler,
  DayView,
  WeekView,
  MonthView,
  Appointments,
  AppointmentTooltip,
  CurrentTimeIndicator,
  AllDayPanel,
} from "@devexpress/dx-react-scheduler-material-ui"

import MonthCellView from "./MonthCellView"
import Loading from "@mobilemind/common/src/components/Loading"
import ButtonSmall from "../../../components/ButtonSmall"
import Filters from "../../../icons/Filters"

import CurrentTimeLine from "./CurrentTimeLine"
import CalendarIntegrationModal from "./CalendarIntegrationModal"

import { Appointment } from "./Appointment"
import { CalendarListView } from "./CalendarListView"

const mapStateToProps = ({ tags, calendar, locations, session }) => {
  return {
    locations,
    tags,
    calendar,
    session,
  }
}

const mapDispatchToProps = {
  toggleOnlyMy,
  changeNavbar,
  setSearchQuery,
  setCurrentDate,
  getLocations,
  setTags,
  debouncedFetchCalendar,
  claimRecommended,
}

function CalendarLayout(props) {
  return <CalendarContent {...props} theme={theme} />
}

class CalendarContent extends Component {
  constructor(props) {
    super(props)
    this.state = {
      currentView: "Month",
      visible: false,
      isTagFieldInUse: false,
      isGoogleConfirmOpen: false,
      fetched: false,
      appointmentMeta: {
        target: null,
        data: {},
      },
    }

    this.toggleVisibility = () => {
      const { visible: tooltipVisibility } = this.state
      this.setState({ visible: !tooltipVisibility })
    }

    this.onAppointmentMetaChange = ({ data, target }) => {
      this.setState({ appointmentMeta: { data, target } })
    }

    this.myAppointment = this.myAppointment.bind(this)
  }

  componentDidMount = async () => {
    this.props.changeNavbar("events")
    window.scrollTo(0, 0)

    !this.props.locations.fetched && (await this.props.getLocations())

    const { currentDate } = this.props.calendar

    const dateRange = {
      min: moment(currentDate)
        .subtract(1, "month")
        .startOf("month")
        .format("YYYY-MM-DD"),
      max: moment(currentDate)
        .add(1, "month")
        .endOf("month")
        .format("YYYY-MM-DD"),
    }

    await this.props.debouncedFetchCalendar({ dateRange })
    this.setState({ fetched: true })
    this.props.setSearchQuery("")
    document.addEventListener("keydown", this.handleKeyDown)
  }

  myAppointment({ children, style, ...restProps }) {
    restProps.currentView = this.state.currentView
    return (
      <Appointment
        {...restProps}
        style={{
          ...style,
          borderRadius: "10px",
        }}
        toggleVisibility={this.toggleVisibility}
        onAppointmentMetaChange={this.onAppointmentMetaChange}
      />
    )
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.handleKeyDown)
  }

  addRemoveTags = (tag, method) => {
    this.props.setTags({ tag, method })
  }

  adjustMonth = async (direction) => {
    let newDate
    const { currentDate } = this.props.calendar
    if (direction === "back") {
      newDate = moment(currentDate)
        .subtract(1, this.state.currentView.toLowerCase())
        .format("YYYY-MM-DD")
    } else {
      newDate = moment(currentDate)
        .add(1, this.state.currentView.toLowerCase())
        .format("YYYY-MM-DD")
    }

    const dateRange = {
      min: moment(newDate)
        .subtract(1, "month")
        .startOf("month")
        .format("YYYY-MM-DD"),
      max: moment(newDate).add(1, "month").endOf("month").format("YYYY-MM-DD"),
    }

    this.props.setCurrentDate(newDate)
    this.props.debouncedFetchCalendar({ dateRange })
  }

  currentViewChange = (event) => {
    window.scrollTo(0, 0)

    if (event.target.value !== "Schedule") {
      this.props.setSearchQuery("")
    }

    this.setState({ currentView: event.target.value })
  }

  handleKeyDown = (event) => {
    // Don't change the calendar on keydown if we're using the externalPD modal
    if (!this.props.isRequestModalOpen && !this.state.isTagFieldInUse) {
      const dateRange = {
        min: moment()
          .subtract(1, "month")
          .startOf("month")
          .format("YYYY-MM-DD"),
        max: moment().add(1, "month").endOf("month").format("YYYY-MM-DD"),
      }

      if (!this.state.isFormVisible && !this.state.searchActive) {
        if (event.keyCode === 68) {
          this.setState({ currentView: "Day" })
        }
        if (event.keyCode === 77) {
          this.setState({ currentView: "Month" })
        }
        if (event.keyCode === 87) {
          this.setState({ currentView: "Week" })
        }
        if (event.keyCode === 83) {
          this.setState({ currentView: "Schedule" })
          this.props.debouncedFetchCalendar({ dateRange })
          window.scrollTo(0, 0)
        } else {
          this.props.setSearchQuery("")
        }
      }
    }
  }

  render() {
    const { calendar, tags, theme } = this.props
    const { currentDate } = calendar
    const { currentView, visible, appointmentMeta } = this.state

    const primary = new Values(theme.palette.primary.main)
    const secondary = new Values(theme.palette.secondary.main)

    let headerDateFormat = "MMMM YYYY"
    if (currentView === "Day") {
      headerDateFormat = "MMMM Do YYYY"
    }

    function TimeTableCell({ onDoubleClick, ...restProps }) {
      if (currentView === "Week") {
        return <WeekView.TimeTableCell onClick={onDoubleClick} {...restProps} />
      }
      if (currentView === "Day") {
        return <DayView.TimeTableCell onClick={onDoubleClick} {...restProps} />
      } else {
        return <MonthCellView {...restProps} />
      }
    }

    function AllDayCell(props) {
      const height = 60
      return <AllDayPanel.Cell style={{ height }} {...props} />
    }

    let startingData =
      currentView === "Month" ? calendar.truncatedData : calendar.data

    if (!startingData) {
      startingData = []
    }

    let data = calendar.onlyMyEvents
      ? startingData.filter((event) => event.rsvp === "Accept" || event.goal)
      : startingData

    const finalData = data
      ? data.filter((event) => {
          let searchMatch

          if (event.title) {
            searchMatch = event.title
              .toLowerCase()
              .includes(calendar.searchQuery.toLowerCase())
          } else if (event.goal) {
            searchMatch = event.goal.attributes.name
              .toLowerCase()
              .includes(calendar.searchQuery.toLowerCase())
          }

          let passSearch =
            (searchMatch && calendar.searchQuery) || !calendar.searchQuery

          let isMyEvent = event.rsvp === "Accept" || event.goal
          let passMyEvent =
            (isMyEvent && calendar.onlyMyEvents) || !calendar.onlyMyEvents

          let passTags = !calendar.selectedTags.length

          calendar.selectedTags.length &&
            calendar.selectedTags.forEach((selected) => {
              passTags =
                event.field_tags && event.field_tags.includes(selected.text)
            })

          return passMyEvent && passSearch && passTags
        })
      : []

    const filtersActive =
      calendar.onlyMyEvents ||
      calendar.selectedTags.length ||
      calendar.searchQuery.length

    return (
      <div
        className={classNames(
          "page calendarLayout",
          currentView !== "Schedule" && "addPadding",
          this.state.filtersOpen && "filtersOpen"
        )}
      >
        <CalendarIntegrationModal
          integrationName="Outlook Calendar"
          integrationKey={"outlook"}
          open={calendar.isOutlookModalOpen}
        />
        <CalendarIntegrationModal
          integrationName="Google Calendar"
          integrationKey={"google"}
          open={calendar.isGCalModalOpen}
        />

        {calendar.fetched && <div className="pageHidden" />}

        <div className="pageHidden top" />
        <div className={classNames("calendarInner", currentView)}>
          <header
            className="toolbar"
            style={{
              transition: ".4s",
              height: this.state.filtersOpen ? 135 : 65,
              display: "flex",
              padding: 10,
              overflow: this.state.filtersOpen ? "visible" : "hidden",
              alignItems: "flex-start",
              flexDirection: "row",
            }}
          >
            <div style={{ display: "flex", alignItems: "center", flex: 1 }}>
              {this.state.currentView !== "Schedule" && (
                <ButtonSmall
                  className={classNames(
                    "button small",
                    currentView === "Schedule" && "hidden"
                  )}
                  onClick={async () => {
                    this.setState({ fetched: false })
                    this.props.setCurrentDate(moment().format("YYYY-MM-DD"))

                    const dateRange = {
                      min: moment()
                        .subtract(1, "month")
                        .startOf("month")
                        .format("YYYY-MM-DD"),
                      max: moment()
                        .add(1, "month")
                        .endOf("month")
                        .format("YYYY-MM-DD"),
                    }

                    await this.props.debouncedFetchCalendar({ dateRange })
                    this.setState({ fetched: true })
                  }}
                >
                  Today
                </ButtonSmall>
              )}

              <div
                className={classNames(
                  "monthSwitcher",
                  currentView === "Schedule" && "hidden"
                )}
              >
                <div
                  className="caret"
                  onClick={() => {
                    this.adjustMonth("back")
                  }}
                />
                <div
                  className="caret flipped"
                  onClick={() => {
                    this.adjustMonth("forward")
                  }}
                />
                {currentView !== "Week" ? (
                  <span>{moment(currentDate).format(headerDateFormat)}</span>
                ) : (
                  <span>
                    {moment(currentDate).startOf("week").format("MMMM Do")} -{" "}
                    {moment(currentDate).endOf("week").format("MMMM Do")}{" "}
                    {moment(currentDate).format("YYYY")}{" "}
                  </span>
                )}
              </div>
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-end",
              }}
            >
              <div className="flexRow" style={{ justifyContent: "flex-end" }}>
                <div className="viewControl">
                  <FormControl className="inputSelect viewSelect">
                    <Select
                      labelId="view-select"
                      id="view-select"
                      value={currentView}
                      onChange={this.currentViewChange}
                    >
                      <MenuItem className="keySelect" value={"Day"}>
                        <span className="label">Day</span>
                        <strong>D</strong>
                      </MenuItem>
                      <MenuItem className="keySelect" value={"Week"}>
                        <span className="label">Week</span>
                        <strong>W</strong>
                      </MenuItem>
                      <MenuItem className="keySelect" value={"Month"}>
                        <span className="label">Month</span>
                        <strong>M</strong>
                      </MenuItem>
                      <MenuItem className="keySelect" value={"Schedule"}>
                        <span className="label">Schedule</span>
                        <strong>S</strong>
                      </MenuItem>
                    </Select>
                  </FormControl>
                </div>

                <div style={{ marginRight: -15 }}>
                  <ButtonSmall
                    onClick={() =>
                      this.setState({ filtersOpen: !this.state.filtersOpen })
                    }
                  >
                    <Filters active={filtersActive} />
                  </ButtonSmall>
                </div>
                {(!this.state.fetched || !calendar.fetched) && (
                  <Loading color={theme.palette.secondary.main} />
                )}
                {this.state.fetched &&
                  calendar.fetched &&
                  calendar.isFetchingMore &&
                  this.state.currentView === "Schedule" && (
                    <Loading color={theme.palette.secondary.main} />
                  )}
              </div>
            </div>

            <div className="filtersRow">
              <div className="searchWrapper">
                <div className="flexRow inputSearch">
                  <TextField
                    variant="outlined"
                    label="Search Events"
                    value={calendar.searchQuery}
                    onFocus={() => this.setState({ searchActive: true })}
                    onBlur={() => this.setState({ searchActive: false })}
                    onChange={(event) => {
                      this.setState({ currentView: "Schedule" })
                      this.props.setSearchQuery(event.target.value)
                    }}
                  />
                </div>
              </div>

              <TagSelect
                allowNewTags={true}
                tags={tags}
                handleInputChange={() => {
                  this.setState({ isTagFieldInUse: true })
                }}
                placeholder={"Search by Tag"}
                addTag={(tag) => this.addRemoveTags(tag, "add")}
                deleteTag={(tag) => this.addRemoveTags(tag, "remove")}
                selectedTags={calendar.selectedTags}
              />

              <FormControlLabel
                control={
                  <Checkbox
                    style={{
                      color: theme.palette.secondary.main,
                    }}
                    checked={calendar.onlyMyEvents}
                    onChange={(event) => {
                      this.props.toggleOnlyMy(event.target.checked)
                    }}
                  />
                }
                label="Only My Events"
                className="checkbox"
              />
            </div>
          </header>

          <Paper>
            <Scheduler theme={theme} data={finalData}>
              {currentView === "Schedule" && (
                <CalendarListView {...this.props} data={data} />
              )}

              <ViewState
                currentDate={currentDate}
                currentViewName={currentView}
              />

              <MonthView timeTableCellComponent={TimeTableCell} />
              <WeekView cellDuration={60} startDayHour={0} />
              <DayView cellDuration={60} startDayHour={0} />

              <Appointments appointmentComponent={this.myAppointment} />

              <AppointmentTooltip
                visible={visible}
                contentComponent={EventTooltip}
                onVisibilityChange={this.toggleVisibility}
                appointmentMeta={appointmentMeta}
                onAppointmentMetaChange={this.onAppointmentMetaChange}
              />

              <AllDayPanel cellComponent={AllDayCell} />

              <CurrentTimeIndicator indicatorComponent={CurrentTimeLine} />
            </Scheduler>
          </Paper>
          <div className="calendar-legend flexRow">
            <div className="item">
              <div
                className="circle"
                style={{ backgroundColor: primary.tint(50).hexString() }}
              />
              <strong>Event</strong>
            </div>
            <div className="item">
              <div
                className="circle"
                style={{ backgroundColor: primary.shade(15).hexString() }}
              />
              <strong>Conference</strong>
            </div>
            <div className="item">
              <div
                className="circle"
                style={{ backgroundColor: theme.palette.secondary.main }}
              />
              <strong>Goal Deadline</strong>
            </div>
            <div className="item">
              <div
                className="circle"
                style={{ backgroundColor: secondary.shade(35).hexString() }}
              />
              <strong>External Event</strong>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CalendarLayout)
