import { useEffect, useState } from "react"
import { useDispatch } from "react-redux"

import classNames from "classnames"
import AliceCarousel from "react-alice-carousel"
import "react-alice-carousel/lib/alice-carousel.css"

import Values from "values.js"

import Loading from "../../components/Loading"
import BackpackProps from "../../types/backpack"

import { generateTimestamp } from "../../functions"
import theme from "../../theme/theme"
import { UserRubricHistoryItem } from "../../types/rubrics"
import { SlideItem } from "./ContentRow"
import he from "he"

interface RowProps {
  data: UserRubricHistoryItem[]
  backpack: BackpackProps
  goTo?: (location: string) => void
  increaseRubricPage: (arg: number) => void
  setRubricCarousel: (arg: number) => void
  fetchSingleUserRubric?: (userRubricId: string) => void
  userId?: number
  // eslint-disable-next-line
  session: any
}

const UserRubricRow = (props: RowProps) => {
  const {
    setRubricCarousel,
    increaseRubricPage,
    fetchSingleUserRubric,
    backpack,
    data,
  } = props

  const [chunkSize, setChunkSize] = useState(5)

  const dispatch = useDispatch()

  function onResize() {
    if (window.innerWidth < 1275) {
      setChunkSize(3)
    } else if (window.innerWidth < 1600) {
      setChunkSize(4)
    } else {
      setChunkSize(5)
    }
  }

  useEffect(() => {
    onResize()
    window.addEventListener("resize", onResize)
    return () => {
      window.removeEventListener("resize", onResize)
    }
  }, [])

  const chunkedData = []

  for (let i = 0; i < data.length; i += chunkSize) {
    const chunk = data.slice(i, i + chunkSize)
    chunkedData.push(chunk)
  }

  function nextSlideChanged(event: SlideItem) {
    dispatch(
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      setRubricCarousel(event.item > backpack.userRubrics.activeIndex ? 1 : -1)
    )
  }

  function nextSlide(event: SlideItem) {
    if (event.itemsInSlide - 2 === event.item) {
      if (
        backpack.userRubrics.currentPage <
        backpack.userRubrics.totalPages - 1
      ) {
        dispatch(
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          increaseRubricPage(event.item)
        )
      }
    }
  }

  const items = chunkedData.map(
    (chunk: UserRubricHistoryItem[], index: number) => {
      return (
        <div key={index} className="chunk" style={{ flexWrap: "wrap" }}>
          {chunk.map((userRubric: UserRubricHistoryItem, index: number) => {
            return (
              <div key={index} className="item user-rubric-item">
                <div
                  onClick={() => {
                    fetchSingleUserRubric?.(userRubric.id)
                  }}
                  style={{ position: "relative" }}
                >
                  <div
                    className="rubric-background"
                    style={{
                      backgroundColor: new Values(theme.palette.primary.main)
                        .shade(35)
                        .hexString(),
                    }}
                  >
                    <span className="title">{he.decode(userRubric.name)}</span>

                    <div className="flexRow score-container">
                      {userRubric.total_score && (
                        <div
                          className="rubric-score"
                          style={{ color: theme.palette.secondary.main }}
                        >
                          <div className="icon clipboard" />
                          {userRubric.total_score}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="timestamp">
                  Completed {generateTimestamp(userRubric.field_completed_date)}
                </div>
              </div>
            )
          })}
        </div>
      )
    }
  )

  return (
    <div
      className={classNames(
        "inner inner-carousel",
        !backpack.userRubrics.activeIndex && "disablePrev",
        chunkedData.length - 1 === backpack.userRubrics.activeIndex &&
          "disableNext"
      )}
    >
      {backpack.userRubrics.isFetching && <Loading />}

      {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        <AliceCarousel
          infinite={true}
          activeIndex={backpack.userRubrics.activeIndex}
          disableDotsControls={chunkedData.length === 1}
          paddingRight={25}
          paddingLeft={25}
          autoWidth={true}
          items={items}
          onSlideChange={(event) => nextSlide(event)}
          onSlideChanged={(event) => nextSlideChanged(event)}
        />
      }
    </div>
  )
}

export default UserRubricRow
