import { Component } from "react"
import { Card, Tab } from "@mui/material"
import he from "he"

import ButtonSmall from "../../components/ButtonSmall"
import { Link } from "react-router-dom"
import moment from "moment"
import classNames from "classnames"

import "../../styles/event-single.scss"
import { TabsCapsule } from "@mobilemind/common/src/components/tabsCapsule/TabsCapsule"

function generateCalendarTimestamp(date) {
  if (
    moment(date).format("YYYY-MM-DD") ===
    moment().add(1, "days").format("YYYY-MM-DD")
  ) {
    return "Tomorrow at " + moment(date).format("HH:mma")
  }
  if (moment(date).format("YYYY-MM-DD") === moment().format("YYYY-MM-DD")) {
    return "Today at " + moment(date).format("h:mma")
  } else {
    return moment(date).format("MMMM Do h:mma")
  }
}

class WidgetSync extends Component {
  state = {
    currentTab: "rsvp",
  }

  handleTabChange = (currentTab) => {
    this.setState({ currentTab })
  }

  componentDidMount() {
    if (!this.props.sync.RSVPd.length) {
      this.setState({ currentTab: "all" })
    }
  }

  render() {
    const { theme } = this.props
    const { currentTab } = this.state
    const events =
      currentTab === "rsvp" ? this.props.sync.RSVPd : this.props.sync.upcoming

    let maxEvents = Math.max(
      this.props.sync.RSVPd.length,
      this.props.sync.upcoming.length
    )
    let height = 165 + maxEvents * 45

    return (
      <Card
        className="widget sync"
        style={{ height, animationDelay: this.props.animationDelay }}
      >
        <div
          className="widgetLabel"
          style={{ backgroundColor: theme.palette.primary.main }}
        >
          Upcoming Events
        </div>
        <div className="widgetIcon icon calendar"></div>
        <TabsCapsule
          value={currentTab}
          onChange={(event, newValue) => this.handleTabChange(newValue)}
          variant="fullWidth"
          sx={{ mt: 3 }}
        >
          <Tab value="rsvp" label="Attending" />
          <Tab value="all" label="All Events" />
        </TabsCapsule>
        {events.length > 0 && (
          <ul>
            {events.map((event) => {
              const isConference = event.field_award_credit_for_conferenc
              const isObservation = event.type === "observation"
              let path = isConference
                ? "events/conference/" + event.id
                : "/events/event/" + event.id
              if (isObservation) {
                path = "/events/observation/" + event.id
              }

              return (
                <li key={event.id}>
                  <Link to={path}>
                    <div>
                      <strong>
                        {generateCalendarTimestamp(
                          event.field_event_date_time_value
                        )}
                      </strong>
                      <span className="name">{he.decode(event.name)}</span>
                    </div>
                    {}
                    <span className={classNames("icon ", event.field_rsvp)} />
                  </Link>
                </li>
              )
            })}
          </ul>
        )}

        {events.length < 1 && (
          <p>
            {currentTab === "rsvp" ? (
              <em>You are not attending any upcoming events.</em>
            ) : (
              <em>You don't have any upcoming events.</em>
            )}
          </p>
        )}

        <footer
          style={{
            marginTop: 5,
            position: "absolute",
            bottom: 15,
            right: 20,
          }}
        >
          <Link to={"/events"}>
            <ButtonSmall>View Calendar</ButtonSmall>
          </Link>
        </footer>
      </Card>
    )
  }
}

export default WidgetSync
