import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import fetchWrapper from "@mobilemind/common/src/functions/fetchWrapper"
import qs from "qs"
import debounceThunk from "@mobilemind/common/src/functions/debounceThunk"
import _ from "lodash"

export const getSingleLP = createAsyncThunk(
  "learningPathSlice/getSingleLP",
  async (args, thunkAPI) => {
    const { id, fullPath } = args
    const { session } = thunkAPI.getState()

    const courseUrl = session.isPartner
      ? "/api/mm_partner_portal/course_explore?"
      : "/api/course_entity/explore?"

    const pathsUrl = session.isPartner
      ? "/api/mm_partner_portal/lp_explore?"
      : "/api/learning_path/explore?"

    /**
     * We're gonna fetch all courses for this Learning Path, one page at a time,
     * then build out an array of courses to order correctly and get into the state
     */
    let courses = []
    let query = { lp: id }

    let pages = 1
    let i = 0
    let response

    while (i < pages) {
      // Set the offset based on what page we're on, then execute the request
      query.page = { offset: i * 25 }
      response = await fetchWrapper.get(courseUrl + qs.stringify(query))
      let data = await response.json()

      if (response.ok) {
        // Stick the newly fetched courses in our array
        courses = courses.concat(data.data)

        // Determine how many total pages we'll need to fetch
        pages = Math.ceil(Number(data.total_records) / 25)
      }
      i++
    }

    /**
     * Once we've gotten all the courses, we need to get the LP itself and order
     * the courses as they appear on the Learning Path
     */

    let learningPathResponse, learningPath, pathCourseIds, coursesInOrder

    // If we're loading the page directly and we don't have the full LP in the state
    if (!fullPath) {
      learningPathResponse = await fetchWrapper.get(
        pathsUrl + qs.stringify({ lp: id })
      )

      if (learningPathResponse && learningPathResponse.ok) {
        learningPath = await learningPathResponse.json()
        pathCourseIds = learningPath.lp_data[0].field_course_list.split(",")
      }
    }

    // Otherwise we have the course IDs already
    else {
      pathCourseIds = fullPath.field_course_list.split(",")
    }

    /**
     * Now with an array of drupal IDs for the courses, let's iterate through
     * the list of courses and stick them in a new array called `coursesInOrder`
     */
    coursesInOrder = pathCourseIds
      .map((courseId) => {
        return courses.find((course) => course.id === courseId)
      })
      .filter((course) => course)

    /**
     * Return the learning path whether we have it already or
     * if we had to fetch it, as well as the courses
     */
    return {
      learningPath: fullPath ? fullPath : learningPath.lp_data[0],
      courses: coursesInOrder,
    }
  }
)

export const getLearningPathTypes = createAsyncThunk(
  "learningPathSlice/getLearningPathTypes",
  async () => {
    let response = await fetchWrapper.get(
      "/api/taxonomy_term/learning_path_types"
    )
    let data = await response.json()
    let ordered = _.orderBy(data.data, (type) => type.attributes.name, [
      "asc",
    ]).filter((type) => type.attributes.name !== "Other")

    ordered.push(data.data.find((type) => type.attributes.name === "Other"))

    return ordered
  }
)

export const getLearningPaths = createAsyncThunk(
  "learningPathSlice/getLearningPaths",
  async (args, thunkAPI) => {
    const { learningPaths, session } = thunkAPI.getState()

    const url = session.isPartner
      ? "/api/mm_partner_portal/lp_explore?"
      : "/api/learning_path/explore?"

    // Figure out our query paramaters
    const { search, org, status, type, uuid, sort_by } =
      args && args !== "refreshing" ? args : learningPaths.filters
    let query = { search, org, status, type, uuid, sort_by }

    // If we're going to the next page, set the offset, otherwise clear it out
    if (args) {
      query.page = args.nextPage
        ? { offset: args.nextPage * 25 }
        : { offset: 0 }
    }

    // Fetch the learning paths and return
    let response = await fetchWrapper.get(url + qs.stringify(query))
    if (response.ok) {
      let data = await response.json()
      return data
    }
  }
)

const debouncedGetLearningPaths = debounceThunk(getLearningPaths, 650)

export const updateFilters = createAsyncThunk(
  "learningPathsSlice/updateFilters",
  async (args, thunkAPI) => {
    const currentFilters = thunkAPI.getState().learningPaths.filters
    const { field, value } = args

    let filters = { ...currentFilters }
    filters[field] = value

    if (field === "search") {
      thunkAPI.dispatch(debouncedGetLearningPaths(filters))
    } else {
      thunkAPI.dispatch(getLearningPaths(filters))
    }

    return args
  }
)

export const resetFilters = createAsyncThunk(
  "learningPathsSlice/resetFilters",
  async (args, thunkAPI) => {
    const filters = {
      search: "",
      org: "any",
      status: "any",
      type: "any",
      sort_by: "status",
    }

    thunkAPI.dispatch(getLearningPaths(filters))
    return filters
  }
)

export const increaseCurrentPage = createAsyncThunk(
  "learningPathSlice/increaseCurrentPage",
  async (args, thunkAPI) => {
    const { learningPaths } = thunkAPI.getState()

    const filters = { ...learningPaths.filters }
    filters.nextPage = learningPaths.currentPage

    thunkAPI.dispatch(getLearningPaths(filters))
    return filters
  }
)

export const learningPathSlice = createSlice({
  name: "learningPathSlice",
  initialState: {
    isFetching: true,
    isFetchingMore: false,
    data: [],
    currentPage: 0,
    filters: {
      search: "",
      org: "any",
      status: "any",
      type: "any",
      sort_by: "status",
    },
    types: {
      fetched: false,
      data: [],
    },
  },
  reducers: {
    createUserLearningPath: (state, action) => {
      let targetPathUuid =
        action.payload.relationships.field_learning_path.data.id

      let learningPathsData = [...state.data]
      let targetPath = learningPathsData.find(
        (path) => path.field_lp_uuid === targetPathUuid
      )
      targetPath.field_user_lp_status = "in_progress"
      targetPath.field_user_lp_uuid = action.payload.id
      targetPath.field_user_lp_completed_courses = action.payload.relationships
        .field_courses_completed.data
        ? action.payload.relationships.field_courses_completed.data.length
        : 0
      targetPath.field_lp_percent_complete =
        targetPath.field_user_lp_completed_courses /
        targetPath.field_num_courses
      state.data = learningPathsData
    },
  },
  extraReducers: {
    [getSingleLP.fulfilled]: (state, action) => {
      const learningPath = { ...action.payload.learningPath }
      learningPath.courses = action.payload.courses

      let targetPath = state.data.find(
        (existing) => existing.field_lp_id === learningPath.field_lp_id
      )

      targetPath
        ? (targetPath.courses = action.payload.courses)
        : state.data.push(learningPath)
    },
    [updateFilters.fulfilled]: (state, action) => {
      state.filters[action.payload.field] = action.payload.value
      state.currentPage = 0
    },
    [resetFilters.fulfilled]: (state, action) => {
      if (action.payload) {
        state.filters = action.payload
      }
    },
    [increaseCurrentPage.pending]: (state, action) => {
      state.currentPage = state.currentPage + 1
    },
    [getLearningPaths.pending]: (state, action) => {
      state.totalPages = 0
      if (!state.currentPage && action.meta.arg !== "refreshing") {
        state.isFetching = true
      } else if (action.meta.arg !== "refreshing") {
        state.isFetchingMore = true
      }
    },
    [getLearningPaths.fulfilled]: (state, action) => {
      let newData = action.payload.lp_data

      state.hasFetched = true
      state.isFetching = false
      state.isFetchingMore = false
      state.totalPages = Math.ceil(action.payload.total_records / 25)
      state.totalRecords = action.payload.total_records

      if (!state.currentPage) {
        state.data = newData
      } else {
        state.data = state.data.concat(newData)
      }
    },
    [getLearningPathTypes.fulfilled]: (state, action) => {
      state.types.fetched = true
      state.types.data = action.payload
    },
  },
})

export default learningPathSlice.reducer
