import { isBefore, isAfter } from "date-fns"
import moment from "moment"

export function formatConferenceEvents({
  conference,
  tags,
  rooms,
  userEvent,
  loadConferenceEvent,
}) {
  return conference.conferenceEvents.map((event) => {
    let sessionUserEvent,
      eventTags = []
    conference.conferenceUserEvents.data.forEach((entity) => {
      if (entity.relationships.field_event.data.id === event.id) {
        sessionUserEvent = entity
      }
    })

    if (event.relationships.field_tags.data.length) {
      event.relationships.field_tags.data.forEach((existing) => {
        const fullTag = tags.data.find((tag) => tag.id === existing.id)
        if (fullTag) {
          eventTags.push(fullTag.attributes.name)
        }
      })
    }

    let canRegister = false
    if (
      event.attributes.field_registration_start &&
      event.attributes.field_registration_end
    ) {
      if (
        isBefore(
          new Date(),
          new Date(event.attributes.field_registration_end)
        ) &&
        isAfter(new Date(), new Date(event.attributes.field_registration_start))
      ) {
        canRegister = true
      }
    } else if (
      isBefore(
        new Date(),
        new Date(event.attributes.field_event_date_time[0].end_value)
      )
    ) {
      canRegister = true
    } else if (sessionUserEvent && sessionUserEvent.attributes.field_rsvp) {
      canRegister = true
    }

    return {
      conference: conference,
      id: event.id,
      addOnId: event.relationships.field_addon.data.id,
      conferenceUserEvent: userEvent,
      rsvp: sessionUserEvent && sessionUserEvent.attributes.field_rsvp,
      canRegister,
      sessionUserEvent,
      image: event.attributes.field_event_image_path && {
        attributes: {
          uri: {
            url: event.attributes.field_event_image_path,
          },
        },
      },
      tags: eventTags.join(),
      isConference: false,
      isForConference: true,
      maxCapacity: event.attributes.field_capacity,
      field_draft: event.attributes.field_draft,
      field_archive: event.attributes.field_archive,
      drupal_internal__id: event.attributes.drupal_internal__id,
      startDate: moment(
        event.attributes.field_event_date_time[0].value
      ).subtract(moment().isDST() ? 0 : 1, "hours")._i,
      endDate: moment(
        event.attributes.field_event_date_time[0].end_value
      ).subtract(moment().isDST() ? 0 : 1, "hours")._i,
      title: event.attributes.name,
      description:
        event.attributes.field_description &&
        event.attributes.field_description.value
          ? event.attributes.field_description.value
          : "",
      category: event.relationships.field_event_category.data
        ? event.relationships.field_event_category.data.id
        : "None",
      roomId: event.relationships.field_room.data
        ? event.relationships.field_room.data.id
        : "none",
      conferenceLocation: event.relationships.field_location.data.id,
      fullRoom: rooms.find(
        (room) =>
          event.relationships.field_room.data &&
          room.id === event.relationships.field_room.data.id
      ),
      categoryId: event.relationships.field_event_category.data.id,
      color: event.attributes.field_hex_color
        ? event.attributes.field_hex_color
        : "#59C3E5",
      loadConferenceEvent: () => loadConferenceEvent(event),
    }
  })
}

export function filterConferenceSessions({ conference, formattedEvents }) {
  return formattedEvents.filter((event) => {
    const filters = conference.scheduleFilters

    const bySearch = filters.searchQuery
      ? event.title.toLowerCase().includes(filters.searchQuery.toLowerCase())
      : true

    const onlyMy = filters.onlyMy ? event.rsvp && event.rsvp === "accept" : true

    let byRoom =
      filters.roomId !== "any" ? event.roomId === filters.roomId : true
    if (filters.roomId === "All Rooms") {
      byRoom = true
    }

    const byCategory = filters.category
      ? event.category === filters.category.id
      : true

    let byLocation =
      conference.conferenceLocations.length > 1
        ? event.fullRoom?.relationships.field_location.data.id ===
          filters.locationId
        : true

    if (!event.fullRoom) {
      byLocation = true
    }

    let byTags = true
    if (filters.tags.length) {
      filters.tags.length &&
        filters.tags.forEach((selectedTag) => {
          if (!event.tags.includes(selectedTag.text)) {
            byTags = false
          }
        })
    }

    return (
      bySearch &&
      onlyMy &&
      byRoom &&
      byLocation &&
      byCategory &&
      // Make sure they have a user event
      event.sessionUserEvent &&
      byTags
    )
  })
}
