/*global chrome*/
import moment from "moment"
import qs from "qs"
import fetchWrapper from "@mobilemind/common/src/functions/fetchWrapper"
import { setAssessments } from "@mobilemind/common/src/actions/index"
import parseDataUrl from "parse-data-url"
import { isChrome } from "react-device-detect"
import theme from "@mobilemind/common/src/theme/theme"
import { fetchActiveEvent } from "../features/events/eventSingle/activeEventSlice"
import he from "he"

const {
  REACT_APP_CHROME_EXTENSION_ID,
  REACT_APP_EDGE_EXTENSION_ID,
  REACT_APP_BETA_EXTENSION_ID,
} = process.env

/**
 * Attempt to send a message to the extension and get a response
 */
export function sendMessage(message) {
  return (dispatch, getState) => {
    const { session } = getState()

    let extensionId = isChrome
      ? REACT_APP_CHROME_EXTENSION_ID
      : REACT_APP_EDGE_EXTENSION_ID

    const backupExtensionId = REACT_APP_BETA_EXTENSION_ID

    if (
      session.collection &&
      session.collection.field_chrome_extension_id &&
      session.collection.field_edge_extension_id
    ) {
      const { field_chrome_extension_id, field_edge_extension_id } =
        session.collection
      if (field_chrome_extension_id[0] && field_edge_extension_id[0]) {
        extensionId = isChrome
          ? field_chrome_extension_id[0].value
          : field_edge_extension_id[0].value
      }
    }

    // Start with our actual extension ID for now
    chrome.runtime &&
      chrome.runtime.sendMessage(extensionId, message, (response) => {
        if (response && response.version) {
          dispatch({
            type: "extension/getVersion",
            payload: response.version,
          })
        } else {
          // Otherwise try our beta ID
          chrome.runtime.sendMessage(backupExtensionId, message, (response) => {
            if (response && response.version) {
              dispatch({
                type: "extension/getVersion",
                payload: response.version,
              })
            }
          })
        }
      })
  }
}

/**
 * Check to see if the user has at least 1 assessment in any status
 */
export function getSingleAssessment() {
  return async (dispatch, getState) => {
    let query = {
      filter: {
        "user_id.id": getState().session.user.id,
      },
      page: {
        limit: 1,
      },
      include: "field_course",
    }

    try {
      let response = await fetchWrapper.get(
        "/api/assessment_entity/assessment_entity?" + qs.stringify(query)
      )
      if (response.ok) {
        let data = await response.json()
        return data.data
      }
    } catch (err) {
      console.log(err)
    }
  }
}

/**
 * Get assessments for multiple courses for a user
 * @param {array} courseIds Course Ids to find assessments for
 */
export function getAssessmentsForCourses(courseIds) {
  return async (dispatch, getState) => {
    // @todo - stringify this
    let query = ""
    if (courseIds) {
      query = "filter[field_teacher.id]=" + getState().session.user.id
      query += "&filter[course][condition][path]=field_course.id"
      courseIds.forEach((id) => {
        query += "&filter[course][condition][value][]=" + id
      })
      query += "&filter[course][condition][operator]=IN"
    }

    try {
      let response = await fetchWrapper.get(
        "/api/assessment_entity/assessment_entity?" +
          query +
          "&include=field_quiz_answers"
      )
      if (response.ok) {
        let data = await response.json()
        dispatch(setAssessments(data))
        return data.data
      }
    } catch (err) {
      console.log(err)
    }
  }
}

/**
 * Get assessments by status and include the courses referenced to save an additional request
 * @param {string} status The machine readable status of assessments (in_progress, submitted, completed, saved_for_later)
 */
export function getAssessmentsByStatus(status, limit) {
  return async (dispatch, getState) => {
    let session = getState().session
    let orgId =
      session.group && session.group.uuid && session.group.uuid[0].value
    let pages = 1
    let i = 0

    try {
      while (i < pages) {
        let query = {}

        if (status === "submitted") {
          query.filter = {
            "user_id.id": getState().session.user.id,
            status: {
              group: {
                conjunction: "OR",
              },
            },
            "status-submitted": {
              condition: {
                path: "field_status",
                value: "submitted",
                memberOf: "status",
              },
            },
            "status-grading-in-progress": {
              condition: {
                path: "field_status",
                value: "grading_in_progress",
                memberOf: "status",
              },
            },
          }
        } else {
          query.filter = {
            "user_id.id": getState().session.user.id,
            field_status: status,
          }
        }

        query.page = {
          offset: i * 50,
        }

        if (limit) {
          query.page.limit = limit
        }

        query.sort = "-changed"
        query.include = "field_course"

        let response = await fetchWrapper.get(
          "/api/assessment_entity/assessment_entity?" + qs.stringify(query)
        )
        if (response.ok) {
          let data = await response.json()

          pages = Math.ceil(Number(data.meta.count) / 50)

          dispatch(setAssessments(data, !pages || i === pages - 1, status))

          dispatch({
            type: "dashboardSlice/getAssessments",
            payload: data,
            meta: {
              status,
            },
          })

          let courses = data.included
          let coursesByOrg =
            courses &&
            courses.filter(
              (course) =>
                !course.relationships.field_organization.data ||
                course.relationships.field_organization.data.id === orgId
            )

          dispatch({
            type: "courses/get",
            payload: coursesByOrg,
          })
        }
        i++
      }
    } catch (err) {
      console.log(err)
    }
  }
}

export function getNPSSurvey(user) {
  return async (dispatch) => {
    let query = {
      filter: {
        "user_id.id": user.id,
      },
    }

    try {
      let response = await fetchWrapper.get(
        "/api/survey_entity/net_promoter_score?" + qs.stringify(query)
      )
      if (response.ok) {
        let data = await response.json()
        let lastSurvey = data.data.length && data.data[data.data.length - 1]

        dispatch({
          type: "session/getNPSScore",
          payload: lastSurvey,
        })
      }
    } catch (err) {
      console.log(err)
    }
  }
}

export function submitNPSSurvey(survey) {
  return async (dispatch, getState) => {
    try {
      let body = JSON.stringify({
        data: {
          type: "survey_entity--net_promoter_score",
          attributes: survey,
        },
      })

      await fetchWrapper.post(
        "/api/survey_entity/net_promoter_score",
        getState().session.token,
        body
      )
    } catch (err) {
      console.log(err)
    }
  }
}

export function getJobTitles() {
  return async (dispatch) => {
    try {
      let response = await fetchWrapper.get("/api/taxonomy_term/job_titles")
      if (response.ok) {
        let data = await response.json()
        dispatch({
          type: "jobTitles/get",
          payload: data.data,
        })
      }
    } catch (err) {
      console.log(err)
    }
  }
}

export function getBadge(uuid) {
  return async (dispatch, getState) => {
    try {
      const { isPartner } = getState().session
      const url = isPartner
        ? "/api/mm_partner_portal/badges_explore?"
        : "/api/badges_entity/explore?"

      let response = await fetchWrapper.get(url + "uuid=" + uuid)

      if (response.ok) {
        let data = await response.json()

        dispatch({
          type: "badgesSlice/getSingle",
          payload: data.badges_data[0],
        })

        return data.badges_data[0]
      }
    } catch (err) {
      console.log(err)
    }
  }
}

export function collapseSidebar(isCollapsed) {
  let type = isCollapsed ? "sidebar/show" : "sidebar/collapse"
  return (dispatch) => {
    dispatch({ type: type })
  }
}

export function peekSidebar(peek) {
  return (dispatch) => {
    dispatch({
      type: "sidebar/peek",
      payload: peek,
    })
  }
}

export function setSidebar(page, activeItem) {
  return (dispatch) => {
    dispatch({
      type: "sidebar/select",
      payload: page,
      meta: {
        activeItem: activeItem,
      },
    })
  }
}

export function endProductTour() {
  return (dispatch) => {
    dispatch({ type: "productTour/end" })
  }
}

export function startProductTour(trigger) {
  return async (dispatch) => {
    dispatch({
      type: "productTour/start",
      meta: {
        trigger,
      },
    })
  }
}

export function setProductTourStarted(session) {
  return async (dispatch) => {
    let body = JSON.stringify({
      data: {
        id: session.user.id,
        type: "user--user",
        attributes: {
          field_product_tour_started: new moment().format(),
        },
      },
    })

    try {
      let response = await fetchWrapper.patch(
        "/api/user/user/" + session.user.id,
        session.token,
        body
      )
      if (response.ok) {
        let data = await response.json()
        return data.data
      }
    } catch (err) {
      console.log(err)
    }
  }
}

export function setViewStyle(style) {
  return (dispatch) => {
    dispatch({
      type: "viewStyle/select",
      payload: style,
    })
  }
}

export function updateFilters(filters, tab) {
  return async (dispatch) => {
    switch (tab) {
      case "mobile":
        dispatch({
          type: "sidebar/expandMobileFilters",
          payload: filters,
        })
        break
      case "learningPaths":
        dispatch({
          type: "sidebar/updateLearningPathFilters",
          payload: filters,
        })
        break
      case "completed":
        dispatch({
          type: "sidebar/updateCompletedFilters",
          payload: filters,
        })
        break
      case "badgesEarned":
        dispatch({
          type: "sidebar/updateBadgesEarnedFilters",
          payload: filters,
        })
        break
      case "earnBadges":
        dispatch({
          type: "sidebar/updateBadgeFilters",
          payload: filters,
        })
        break
      default:
        dispatch({
          type: "sidebar/updateExploreFilters",
          payload: filters,
        })
    }
  }
}

/**
 * Get only the most recent user badge to show on the dashboard
 */
export function getRecentUserBadge() {
  return async (dispatch, getState) => {
    try {
      let query = {
        earned: "earned",
        sort_by: "earned_date",
        sort_order: "DESC",
      }

      const { isPartner } = getState().session

      const url = isPartner
        ? "/api/mm_partner_portal/badges_explore?"
        : "/api/badges_entity/explore?"

      let response = await fetchWrapper.get(url + qs.stringify(query))

      if (response.ok) {
        let data = await response.json()

        dispatch({
          type: "dashboardSlice/getRecentUserBadge",
          payload: data.badges_data[0],
        })
      }
    } catch (err) {
      console.log(err)
    }
  }
}

export function changeNavbar(activeItem) {
  return (dispatch) => {
    dispatch({
      type: "changeNavbar",
      payload: activeItem,
    })
  }
}

export function getAnnouncements() {
  return async (dispatch, getState) => {
    let session = getState().session
    let orgId =
      session.group && session.group.uuid && session.group.uuid[0].value

    let pages = 1
    let i = 0

    try {
      while (i < pages) {
        let response = await fetchWrapper.get(
          "/api/announcement?include=field_announcement_type,field_media,field_video,field_survey,field_course,field_learning_path&page[offset]=0"
        )
        if (response.ok && orgId) {
          let data = await response.json()
          pages = Math.ceil(Number(data.meta.count) / 50)

          let announcements = Object.keys(data.data).map(
            (key) => data.data[key]
          )

          // Filter out email only announcements
          let nonEmail = announcements.filter(
            (announcement) =>
              !announcement.field_email ||
              !announcement.field_email.length ||
              (announcement.field_email &&
                announcement.field_email[0] &&
                announcement.field_email[0].value !== "email_only")
          )

          // Filter out announcements that don't have your group
          let userGroupIds =
            session.subgroups && session.subgroups[0] && session.subgroups[0][0]
              ? session.subgroups[0].map((group) => group[0].id[0].value)
              : []

          let inGroup = nonEmail.filter((announcement) => {
            let announcementGroups = announcement.field_subgroup
            let hasNoGroup =
              !announcement.field_subgroup ||
              !announcement.field_subgroup.length
            let isInGroup = announcementGroups
              ? announcementGroups.find((group) =>
                  userGroupIds.includes(group.target_id)
                )
              : false
            return isInGroup || hasNoGroup
          })
          // Don't show to collections unless "Include Collections" is checked
          let isInCollection = inGroup.filter(
            (announcement) =>
              !session.collection ||
              (session.collection &&
                announcement.field_include_collections_learn[0] &&
                announcement.field_include_collections_learn[0].value)
          )

          dispatch(
            {
              type: "announcements/get",
              payload: isInCollection,
              meta: {
                included: data.included,
              },
            },
            {
              type: "dashboardSlice/getAnnouncements",
              payload: isInCollection,
              meta: {
                included: data.included,
              },
            }
          )
        }
        i++
      }
    } catch (err) {
      console.log(err)
    }
  }
}

export function getQuickLinks() {
  return async (dispatch, getState) => {
    let session = getState().session
    let orgId =
      session.group && session.group.uuid && session.group.uuid[0].value

    let query = {
      filter: {
        "field_organization.id": orgId,
        draft: {
          group: {
            conjunction: "OR",
          },
        },
        null_filter: {
          condition: {
            path: "field_draft",
            operator: "IS NULL",
            memberOf: "draft",
          },
        },
        false_filter: {
          condition: {
            path: "field_draft",
            value: false,
            memberOf: "draft",
          },
        },
      },
    }

    try {
      let response = await fetchWrapper.get(
        "/api/node/quick_links?" + qs.stringify(query)
      )
      if (response.ok) {
        let data = await response.json()
        const quickLinks = data.data[0]

        dispatch({
          type: "dashboardSlice/getQuickLinks",
          payload: quickLinks,
        })

        return quickLinks
      }
    } catch (err) {
      console.log(err)
    }
  }
}

export function deleteAssessment(id, session) {
  return async (dispatch) => {
    try {
      await fetchWrapper.remove(
        "/api/assessment_entity/assessment_entity/" + id,
        session.token
      )
      dispatch({
        type: "assessments/delete",
        payload: id,
      })
    } catch (err) {
      console.log(err)
    }
  }
}

export function createAssessment(session, activeCourse, status) {
  let body = JSON.stringify({
    data: {
      type: "assessment_entity--assessment_entity",
      attributes: {
        status: true,
        field_status: status ? status : "in_progress",
        field_last_updated_by: [
          {
            value: session.user.attributes.drupal_internal__uid,
          },
        ],
      },
      relationships: {
        field_teacher: {
          data: {
            type: "user--user",
            id: session.user.id,
          },
        },
        field_course: {
          data: {
            type: "course_entity--course_entity",
            id: activeCourse.id,
          },
        },
      },
    },
  })

  return async (dispatch) => {
    try {
      let response = await fetchWrapper.post(
        "/api/assessment_entity/assessment_entity",
        session.token,
        body
      )
      if (response.ok) {
        let data = await response.json()

        dispatch({
          type: "assessments/create",
          payload: data.data,
          meta: {
            status,
          },
        })

        return data.data
      }
    } catch (err) {
      console.log(err)
    }
  }
}

export function startOnboarding() {
  return async (dispatch) => {
    dispatch({ type: "onboarding/start" })
  }
}

export function endOnboarding() {
  return async (dispatch) => {
    dispatch({ type: "onboarding/end" })
  }
}

export function setOnboardingFields(field, value) {
  return async (dispatch, getState) => {
    let session = getState().session

    let newProfile = {
      data: {
        id: session.user.id,
        type: "user--user",
        attributes: {},
        relationships: {},
      },
    }

    if (field === "field_initial_goal") {
      newProfile.data.relationships = {
        field_initial_goal: {
          data: {
            type: "taxonomy_term--learning_path_types",
            id: value,
          },
        },
      }
    }

    if (field === "field_computer_comfort") {
      newProfile.data.attributes = {
        field_computer_comfort: value,
      }
    }

    if (field === "field_recommended_learning_path") {
      newProfile.data.relationships["field_recommended_learning_path"] = {
        data: {
          type: "learning_path--learning_path",
          id: value.id,
        },
      }
    }

    if (field === "field_follow_recommendation") {
      newProfile.data.attributes = {
        field_follow_recommendation: value,
      }
    }

    if (field === "field_learning_buddy") {
      newProfile.data.relationships["field_learning_buddy"] = {
        data: {
          type: "taxonomy_term--learning_buddy",
          id: value.id,
        },
      }
    }

    let body = JSON.stringify(newProfile)

    try {
      let response = await fetchWrapper.patch(
        "/api/user/user/" + session.user.id,
        session.token,
        body
      )
      if (response.ok) {
        let data = await response.json()
        return data
      }
    } catch (err) {
      console.log(err)
    }
  }
}

export function getCertificates(user, type) {
  return async (dispatch) => {
    let pages = 1
    let i = 0

    try {
      while (i < pages) {
        let query = {
          filter: {
            "user_id.id": {
              value: user.id,
            },
          },
          page: {
            offset: i * 50,
          },
          include: "field_thumbnail",
        }

        let response = await fetchWrapper.get(
          "/api/certificate_entity/" + type + "?" + qs.stringify(query)
        )
        if (response.ok) {
          let data = await response.json()
          pages = Math.ceil(Number(data.meta.count) / 50)

          dispatch({
            type: "session/getCertificates",
            payload: {
              data: data.data,
              fetched: !pages || i === pages - 1,
              included: data.included,
            },
          })
        }
        i++
      }
    } catch (err) {
      console.log(err)
    }
  }
}

export function getCertificate(certificateId, type) {
  return async (dispatch) => {
    let query = {}

    if (type !== "badge" && type !== "event") {
      query.include = "field_time_spent"
    }

    try {
      let response = await fetchWrapper.get(
        "/api/certificate_entity/" +
          type +
          "/" +
          certificateId +
          "?" +
          qs.stringify(query)
      )

      if (response.ok) {
        let data = await response.json()

        dispatch({
          type: "session/getCertificate",
          payload: {
            data: data.data,
            included: data.included,
            fetched: true,
          },
        })

        return data
      }
    } catch (err) {
      console.log(err)
    }
  }
}

export function uploadCertificateImage(image, url, filename) {
  return async (dispatch, getState) => {
    let options = {
      credentials: "include",
      method: "POST",
      headers: new Headers({
        Accept: "application/vnd.api+json",
        "Content-Type": "application/octet-stream",
        "X-CSRF-Token": getState().session.token,
        "Content-Disposition": 'file; filename="' + he.encode(filename) + '"',
      }),
      body: parseDataUrl(image).toBuffer(),
    }

    try {
      let response = await fetch(process.env.REACT_APP_API_URL + url, options)
      if (response.ok) {
        let data = await response.json()
        // Go ahead and return the image if we need to do something with it
        return data.data
      }
    } catch (err) {
      console.log(err)
    }
  }
}

export function updateRSVP({ appointmentData, userEvent, rsvp, activeEvent }) {
  return async (dispatch, getState) => {
    const { session } = getState()
    let atCapacity = false
    let userEventId

    if (userEvent) {
      userEventId = userEvent.id
    } else if (appointmentData) {
      userEventId = appointmentData.sessionUserEvent.id
    }

    let sessionId = appointmentData && appointmentData.drupal_internal__id
    const maxCapacity = appointmentData
      ? appointmentData.maxCapacity
      : activeEvent.sessionMaxCapacity

    let eventId = sessionId ? sessionId : activeEvent.drupal_internal__id
    if (rsvp === "accept") {
      const attendance = await fetchWrapper.get(
        "/api/event_attendance/" + eventId
      )
      if (attendance.ok) {
        let attendanceData = await attendance.json()
        let numAttendees = Number(attendanceData.attendance_data.accept_events)

        if (maxCapacity > 0 && numAttendees === maxCapacity) {
          atCapacity = true
        }
      }
    }
    if (rsvp !== "accept" || !atCapacity) {
      let body = JSON.stringify({
        data: {
          type: "mobile_mind_user_event--user_event_base",
          id: userEventId,
          attributes: {
            field_rsvp: rsvp,
          },
        },
      })

      let response = await fetchWrapper.patch(
        "/api/mobile_mind_user_event/user_event_base/" + userEventId,
        session.token,
        body
      )

      if (response.ok) {
        let data = await response.json()

        dispatch({
          type: "calendarSlice/updateRSVP",
          payload: data.data,
        })

        if (!appointmentData) {
          dispatch({
            type: "activeEventSlice/updateRSVP",
          })

          dispatch(
            fetchActiveEvent({
              id: activeEvent.drupal_internal__id,
              refresh: true,
            })
          )
        } else {
          dispatch({
            type: "activeEventSlice/updateSessionRSVP",
            payload: { appointmentData, rsvp },
          })
        }

        return data.data
      }
    } else {
      dispatch({
        type: "activeEventSlice/atCapacity",
      })
      return false
    }
  }
}

export function getIntroCourse() {
  return async (dispatch, getState) => {
    try {
      const { group, isPartner } = getState().session

      const url = isPartner
        ? "/api/mm_partner_portal/course_explore?"
        : "/api/course_entity/explore?"

      // First get the default MM welcome course for this env
      let course = process.env.REACT_APP_INTRO_COURSE_ID
      // Then check to see if their collection has one and use that instead
      if (theme.introCourse) {
        course = theme.introCourse[process.env.REACT_APP_DEPLOYMENT_ENV]
      }
      // But finally check to see if our organization has specified one
      if (group.field_welcome_course && group.field_welcome_course[0]) {
        course = group.field_welcome_course[0].target_id
      }
      let response = await fetchWrapper.get(url + qs.stringify({ course }))

      if (response.ok) {
        let data = await response.json()
        return data.data && data.data[0]
      }
    } catch (err) {
      console.log(err)
    }
  }
}

//@todo - can get rid of this and use the one in the slice
export function getLearningPathTypes() {
  return async (dispatch) => {
    let response = await fetchWrapper.get(
      "/api/taxonomy_term/learning_path_types"
    )
    let data = await response.json()
    return data.data
  }
}

/**
 * Change a user's Learning Buddy
 */
export function updateLearningBuddy(buddy) {
  return async (dispatch, getState) => {
    let session = getState().session

    let newProfile = {
      data: {
        id: session.user.id,
        type: "user--user",
        relationships: {},
      },
    }

    newProfile.data.relationships["field_learning_buddy"] = {
      data: {
        type: "taxonomy_term--learning_buddy",
        id: buddy.id,
      },
    }

    let body = JSON.stringify(newProfile)

    try {
      let response = await fetchWrapper.patch(
        "/api/user/user/" + session.user.id,
        session.token,
        body
      )
      if (response.ok) {
        let data = await response.json()

        dispatch({
          type: "session/updateLearningBuddy",
          payload: buddy,
        })

        return data
      }
    } catch (err) {
      console.log(err)
    }
  }
}

export function fetchSessionAttendance(id) {
  return async (dispatch, getState) => {
    try {
      let response = await fetchWrapper.get("/api/event_attendance/" + id)
      if (response.ok) {
        let data = await response.json()
        return data
      }
    } catch (err) {
      console.log(err)
    }
  }
}

export function fetchSessionAttendanceLog(addOnId) {
  return async (dispatch, getState) => {
    try {
      // First get the add on
      let response = await fetchWrapper.get(
        "/api/mobilemind_event_addon/event_addon_base/" + addOnId
      )
      if (response.ok) {
        let addOn = await response.json()

        if (addOn.data) {
          let logId =
            addOn.data.relationships.field_attendance_log.data &&
            addOn.data.relationships.field_attendance_log.data.id

          if (logId) {
            let logResponse = await fetchWrapper.get(
              "/api/mm_attendance_log/attendance_log_base/" + logId
            )
            if (logResponse.ok) {
              let log = await logResponse.json()

              return log
            }
          }
        }
      }
    } catch (err) {
      console.log(err)
    }
  }
}

export function submitQuiz({ answerBank, assessment }) {
  return async (dispatch, getState) => {
    const { session } = getState()

    try {
      let i = 0
      let assessmentQuizAnswers = []

      while (i < answerBank.length) {
        let answerBody = {
          data: {
            type: "paragraph--quiz_submission",
            attributes: {
              status: true,
              parent_id: assessment.attributes.drupal_internal__id,
              parent_type: "assessment_entity",
              parent_field_name: "field_quiz_answers",
              field_quiz_answer: answerBank[i].userAnswer,
            },
          },
        }

        let answerPost = await fetchWrapper.post(
          "/api/paragraph/quiz_submission",
          session.token,
          JSON.stringify(answerBody)
        )
        let answerData = await answerPost.json()

        assessmentQuizAnswers.push({
          type: "paragraph--quiz_submission",
          id: answerData.data.id,
          meta: {
            target_revision_id:
              answerData.data.attributes.drupal_internal__revision_id,
          },
        })
        i++
      }

      let assessmentBody = {
        data: {
          id: assessment.id,
          type: "assessment_entity--assessment_entity",
          attributes: {
            field_status: "submitted",
          },
          relationships: {
            field_quiz_answers: {
              data: assessmentQuizAnswers,
            },
          },
        },
      }

      await fetchWrapper.patch(
        "/api/assessment_entity/assessment_entity/" + assessment.id,
        session.token,
        JSON.stringify(assessmentBody)
      )

      // Hit the user goal update endpoint
      fetchWrapper.get(
        "/api/user_goals/update/" + assessment.attributes.drupal_internal__id
      )
    } catch (err) {
      console.log(err)
    }
  }
}

export function updateCalendarIntegrationSettings({ settingsArray }) {
  return async (dispatch, getState) => {
    const { session } = getState()
    const userPatch = {
      data: {
        id: session.user.id,
        type: "user--user",
        attributes: {
          field_calendar_integration: settingsArray,
        },
      },
    }

    fetchWrapper.patch(
      "/api/user/user/" + session.user.id,
      session.token,
      JSON.stringify(userPatch)
    )
    dispatch({
      type: "session/updateIntegrationSettings",
      payload: settingsArray,
    })
  }
}

export function addOutlookEventIdToUserEvent({
  activeEvent,
  userEvent,
  outlookId,
}) {
  return async (dispatch, getState) => {
    const { session } = getState()

    const bundle =
      activeEvent.bundle === "event_base"
        ? "user_event_base"
        : activeEvent.bundle

    try {
      const userEventPatch = {
        data: {
          type: "mobile_mind_user_event--" + bundle,
          id: userEvent.id,
          attributes: {
            field_outlook_event_id: outlookId,
          },
        },
      }

      fetchWrapper.patch(
        "/api/mobile_mind_user_event/" + bundle + "/" + userEvent.id,
        session.token,
        JSON.stringify(userEventPatch)
      )
    } catch (err) {
      console.log(err)
    }
  }
}
