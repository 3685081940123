import { Component } from "react"
import { connect } from "react-redux"

import {
  Paper,
  TextField,
  Tab,
  MenuItem,
  Select,
  FormControl,
  Button,
  Box,
  Typography,
  Stack,
} from "@mui/material"

import {
  ViewState,
  GroupingState,
  IntegratedGrouping,
} from "@devexpress/dx-react-scheduler"

import ConferenceEventList from "./ConferenceEventList"

import classNames from "classnames"
import moment from "moment"

import SessionTooltip from "../calendar/SessionTooltip"

import {
  updateScheduleFilters,
  setActiveSession,
  updateField,
} from "./activeEventSlice"
import theme from "@mobilemind/common/src/theme/theme"
import Filters from "../../../icons/Filters"
import minimizeImg from "../../../img/minimize.svg"
import maximizeImg from "../../../img/maximize.svg"

import "../../../styles/calendar.scss"
import { SettingSwitch } from "@mobilemind/common/src/components/SettingSwitch"
import {
  Scheduler,
  DayView,
  CurrentTimeIndicator,
  Resources,
  Appointments,
  GroupingPanel,
} from "@devexpress/dx-react-scheduler-material-ui"

import { ConferenceAppointment } from "./components/ConferenceAppointment"

import Loading from "@mobilemind/common/src/components/Loading"
import { generateContrastColor } from "@mobilemind/common/src/functions"
import CategorySelect from "@mobilemind/common/src/components/CategorySelect"

import TagSelect from "@mobilemind/common/src/components/TagSelect"

import { ConferenceScheduleFiltersPopper } from "./components/ConferenceScheduleFiltersPopper"

// import { ConferenceScheduleViewSelectTabs } from "@mobilemind/common/src/components/ConferenceScheduleViewSelectTabs"
// import CalendarIcon from "../../../img/calendar.svg"
// import ListViewIcon from "../../../img/list.svg"
import { TabsCapsule } from "@mobilemind/common/src/components/tabsCapsule/TabsCapsule"

import LocationColorIcon from "../../../img/location-color.svg"
import RoomIcon from "../../../img/room.svg"

import { filterConferenceSessions, formatConferenceEvents } from "./utility"

const mapStateToProps = ({ tags, categories, session, activeEvent }) => {
  // Set up an array with our room IDs and their number of events
  let roomLengths = activeEvent.availableRooms.map((room) => {
    return {
      id: room.id,
      numEvents: 0,
    }
  })

  // Tally up all the events in each room
  activeEvent.conferenceEvents.forEach((event) => {
    let roomId = event.relationships.field_room.data
      ? event.relationships.field_room.data.id
      : "none"
    let targetRoom = roomLengths.find((room) => room.id === roomId)
    targetRoom && targetRoom.numEvents++
  })
  // Filter out rooms with zero events
  let allRoomsWithEvents = activeEvent.availableRooms.filter((room) => {
    let target = roomLengths.find((length) => length.id === room.id)
    return target.numEvents
  })

  return {
    tags,
    categories: categories,
    conference: activeEvent,
    rooms: allRoomsWithEvents,
    session,
  }
}

const mapDispatchToProps = {
  updateScheduleFilters,
  updateField,
  setActiveSession,
}

class ConferenceSchedule extends Component {
  constructor(props) {
    super(props)

    // Filter out events that don't belong to the selected location
    let filteredRooms = props.rooms.filter((room) => {
      // IF the conference has more than one location
      if (props.activeEvent.conferenceLocations?.length > 1) {
        return (
          room.relationships.field_location.data.id ===
          props.activeEvent.scheduleFilters.locationId
        )
      }
      // Otherwise, props.rooms should be fine
      return true
    })

    this.state = {
      filtersPopperOpen: false,
      currentDay: 0,
      createAnchorEl: null,
      filtersPopperAnchorEl: null,
      currentDate: props.conference.startDate,
      visible: false,
      isFormVisible: false,
      filtersOpen: false,
      isModalOpen: false,
      currentTab: "list",
      resources: [
        {
          fieldName: "roomId",
          title: " ",
          instances: filteredRooms,
        },
      ],
      grouping: [
        {
          resourceName: "roomId",
        },
      ],
      appointmentMeta: {
        target: null,
        data: {},
      },
    }

    this.toggleVisibility = () => {
      const { visible: tooltipVisibility } = this.state
      this.setState({ visible: !tooltipVisibility })
    }

    this.onAppointmentMetaChange = ({ data, target }) => {
      this.setState({ appointmentMeta: { data, target } })
    }

    this.myAppointment = this.myAppointment.bind(this)
  }

  myAppointment = (props) => {
    return (
      <ConferenceAppointment
        {...props}
        toggleVisibility={this.toggleVisibility}
        onAppointmentMetaChange={this.onAppointmentMetaChange}
        color={props.data.color}
        textColor={generateContrastColor(props.data.color)}
      />
    )
  }

  componentDidMount() {
    window.scrollTo(0, 0)
    // const scheduleEl = document.getElementsByClassName("scheduleWrapper")
    // scheduleEl[0].firstChild.firstChild.scrollTop = 360
    this.props.updateScheduleFilters({ field: "roomId", value: "any" })
    this.props.setActiveSession(null)
  }

  handleDayChange = (event, newValue) => {
    this.setState({
      currentDate: moment(this.props.activeEvent.startDate)
        .add(newValue, "day")
        .format("YYYY-MM-DD"),
    })

    this.setState({ currentDay: newValue })
  }

  handleTabChange = (event, newValue) => {
    this.setState({ currentTab: newValue })

    if (newValue === "calendar") {
      this.props.updateScheduleFilters({ field: "roomId", value: "any" })
    }
  }

  handleListClick = (session) => {
    this.props.history.push(
      "/events/event/" +
        session.drupal_internal__id +
        "/" +
        this.props.conference.drupal_internal__id
    )
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.currentTab === "list" &&
      this.state.currentTab === "calendar"
    ) {
      const scheduleEl = document.getElementsByClassName("scheduleWrapper")
      scheduleEl[0].firstChild.firstChild.scrollTop = 360
    }
  }

  addRemoveTags = (tag, method) => {
    this.props.updateScheduleFilters({ field: "tags", value: tag, method })
  }

  resetFilters = () => {
    this.props.updateScheduleFilters({
      field: "searchQuery",
      value: "",
    })
    this.props.updateScheduleFilters({
      field: "category",
      value: null,
    })
    this.addRemoveTags([], "reset")
    this.setState({
      filtersPopperAnchorEl: null,
      filtersPopperOpen: false,
    })
  }

  render() {
    const { conference, rooms, userEvent, tags, activeEvent } = this.props
    const { grouping, currentDay, currentDate, resources } = this.state

    function TimeTableCell({ onDoubleClick, ...restProps }) {
      return <DayView.TimeTableCell onClick={() => {}} {...restProps} />
    }

    const formattedEvents = formatConferenceEvents({
      conference,
      tags,
      rooms,
      userEvent,
      loadConferenceEvent: (event) => {
        this.props.fetchActiveEvent({
          isForConference: true,
          id: event.attributes.drupal_internal__id,
        })
        this.props.setEditingEvent(true)
      },
    })

    const filteredSessions = filterConferenceSessions({
      conference,
      formattedEvents,
    })

    // Replace this with location or modify if location doesn't have room
    if (!resources[0].instances.length) {
      if (conference.currentLocation) {
        resources[0].instances = [
          { id: "none", text: conference.currentLocation.attributes.name },
        ]
      } else {
        resources[0].instances = [{ id: "none", text: "" }]
      }
    }

    const { category, roomId, onlyMy, searchQuery } = conference.scheduleFilters
    const roomsForSelectedLocation = rooms.filter((room) => {
      return (
        room.relationships?.field_location.data.id ===
        conference.scheduleFilters.locationId
      )
    })

    const activeFilters =
      category !== null ||
      roomId !== "any" ||
      onlyMy ||
      searchQuery.length ||
      conference.scheduleFilters.tags.length

    const duration =
      moment(activeEvent.endDate.split("T")[0]).diff(
        moment(activeEvent.startDate.split("T")[0]),
        "days"
      ) + 1

    return (
      <>
        <ConferenceScheduleFiltersPopper
          anchorEl={this.state.filtersPopperAnchorEl}
          open={this.state.filtersPopperOpen}
          onClose={() => {
            this.setState({
              filtersPopperAnchorEl: null,
              filtersPopperOpen: false,
            })
          }}
        >
          <Box sx={{ maxWidth: 300 }}>
            <Stack
              direction={"row"}
              spacing={1}
              sx={{
                marginBottom: 3,
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Typography sx={{ fontWeight: "bold", fontSize: 16 }}>
                Filter Sessions
              </Typography>

              <Button
                size="small"
                onClick={() => {
                  this.resetFilters()
                }}
              >
                Reset
              </Button>
            </Stack>

            <FormControl margin="normal">
              <TextField
                autoComplete="off"
                sx={{ "& input": { height: "2rem" }, marginBottom: -2 }}
                placeholder={"Search  "}
                value={searchQuery}
                onChange={(event) =>
                  this.props.updateScheduleFilters({
                    field: "searchQuery",
                    value: event.target.value,
                  })
                }
              />
            </FormControl>
            <FormControl
              margin="normal"
              sx={{
                "& .MuiInputBase-root:before": { display: "none" },
                "& input": {
                  marginBottom: 0,
                  paddingLeft: 5,
                },
              }}
            >
              <CategorySelect
                categories={this.props.categories}
                onChange={(event) => {
                  this.props.updateScheduleFilters({
                    field: "category",
                    value: event,
                  })
                }}
                margin="none"
                currentValue={category}
                placeholder={"Filter by Category"}
                allowedLevels={3}
                hideParents={true}
              />
            </FormControl>

            <FormControl>
              <TagSelect
                allowNewTags={true}
                tags={tags}
                placeholder={"Filter by Tag"}
                addTag={(tag) => this.addRemoveTags(tag, "add")}
                deleteTag={(tag) => this.addRemoveTags(tag, "remove")}
                selectedTags={conference.scheduleFilters.tags}
              />
            </FormControl>
            <SettingSwitch
              label={"Only My Sessions"}
              checked={onlyMy}
              helperText={"Only show sessions you have RSVPd to."}
              onChange={(event) =>
                this.props.updateScheduleFilters({
                  field: "onlyMy",
                  value: !onlyMy,
                })
              }
            />
          </Box>
        </ConferenceScheduleFiltersPopper>

        <div
          className={classNames(
            "event-content calendarLayout conferenceCalendar",
            userEvent &&
              userEvent.attributes &&
              userEvent.attributes.field_rsvp !== "accept" &&
              "disabled"
          )}
        >
          <div className="conferenceScheduleWrapper">
            <div
              className={classNames(
                "calendarInner Day",
                duration > 1 && "multiDay"
              )}
            >
              <Box
                sx={{
                  background: "white",
                  position: "relative",
                  zIndex: 8,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  padding: 5,
                  paddingTop: 6,
                }}
              >
                {/* <Box sx={{ position: "absolute", left: 20 }}>
                  <ConferenceScheduleViewSelectTabs
                    value={
                      this.state.currentTab ? this.state.currentTab : "calendar"
                    }
                    onChange={this.handleTabChange}
                  >
                    <Tab
                      value="calendar"
                      icon={
                        <img
                          src={CalendarIcon}
                          height={20}
                          width={20}
                          alt="Calendar View"
                        />
                      }
                    />
                    <Tab
                      value="list"
                      icon={
                        <img
                          src={ListViewIcon}
                          height={20}
                          width={20}
                          alt="List View"
                        />
                      }
                    />
                  </ConferenceScheduleViewSelectTabs>
                </Box> */}

                <Typography sx={{ fontWeight: "bold", fontSize: 18 }}>
                  {moment(this.state.currentDate).format("dddd, MMMM Do YYYY")}
                </Typography>

                <Box sx={{ position: "absolute", right: 20 }}>
                  <Button
                    size="small"
                    onClick={(event) => {
                      this.setState({
                        filtersPopperAnchorEl: event.currentTarget,
                        filtersPopperOpen: !this.state.filtersPopperOpen,
                      })
                    }}
                  >
                    <Filters active={activeFilters} width={15} height={15} />
                  </Button>
                  <Button
                    onClick={() => {
                      this.props.updateField({
                        field: "isFullScreen",
                        value: !this.props.conference.isFullScreen,
                      })
                    }}
                    className={"button small expand"}
                  >
                    <img
                      alt={
                        this.props.conference.isFullScreen
                          ? "Minimize"
                          : "Full Screen"
                      }
                      src={
                        this.props.conference.isFullScreen
                          ? minimizeImg
                          : maximizeImg
                      }
                    />
                  </Button>
                </Box>
              </Box>

              {duration > 1 && (
                <Box
                  sx={{
                    paddingBottom: 3,
                    backgroundColor: "white",
                    zIndex: 8,
                    display: "flex",
                    position: "relative",
                    justifyContent: "center",
                    alignItems: "center",
                    marginBottom: 0,
                    marginTop: -3,
                  }}
                >
                  <TabsCapsule
                    value={currentDay}
                    onChange={this.handleDayChange}
                  >
                    {Array.from(Array(duration).keys()).map((item, index) => {
                      const dayLabel = moment(activeEvent.startDate)
                        .add(item, "days")
                        .format("dddd")

                      return (
                        <Tab
                          sx={(theme) => ({
                            color: "black",
                            backgroundColor:
                              currentDay === index
                                ? theme.palette.secondary.main
                                : "white",
                          })}
                          value={item}
                          label={dayLabel}
                        />
                      )
                    })}
                  </TabsCapsule>
                </Box>
              )}

              {activeEvent.conferenceLocations?.length > 0 && (
                <Box
                  sx={{
                    backgroundColor: "white",
                    zIndex: 8,
                    position: "relative",
                    paddingBottom: 1,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginBottom: 0,
                    marginTop: -2.6,
                  }}
                >
                  <img
                    src={LocationColorIcon}
                    height={20}
                    width={20}
                    alt="Location"
                  />
                  <Select
                    value={
                      conference.scheduleFilters.locationId ??
                      activeEvent.conferenceLocations[0].id
                    }
                    onChange={(event) => {
                      this.props.updateScheduleFilters({
                        field: "locationId",
                        value: event.target.value,
                      })
                      this.props.updateScheduleFilters({
                        field: "roomId",
                        value: "any",
                      })
                      const roomsForLocation = this.props.rooms.filter(
                        (room) =>
                          room.relationships.field_location.data.id ===
                          event.target.value
                      )
                      this.setState({
                        resources: [
                          {
                            fieldName: "roomId",
                            title: " ",
                            instances: roomsForLocation,
                          },
                        ],
                      })
                    }}
                  >
                    {conference.conferenceLocations.map((location) => {
                      return (
                        <MenuItem value={location.id}>
                          {location.attributes.name}
                        </MenuItem>
                      )
                    })}
                  </Select>

                  {this.state.currentTab === "list" && (
                    <>
                      <img
                        style={{ marginLeft: 10 }}
                        src={RoomIcon}
                        height={20}
                        width={20}
                        alt="Location"
                      />
                      <Select
                        value={
                          conference.scheduleFilters.roomId !== "any"
                            ? conference.scheduleFilters.roomId
                            : "All Rooms"
                        }
                        onChange={(event) => {
                          this.props.updateScheduleFilters({
                            field: "roomId",
                            value: event.target.value,
                          })
                        }}
                      >
                        <MenuItem value={"All Rooms"}>All Rooms</MenuItem>
                        {roomsForSelectedLocation.map((room) => {
                          return (
                            <MenuItem value={room.id}>
                              {room.attributes.name}
                            </MenuItem>
                          )
                        })}
                      </Select>
                    </>
                  )}
                </Box>
              )}

              {!conference.conferenceUserEvents.fetched && (
                <Loading
                  color={theme.palette.secondary.main}
                  message="Getting sessions..."
                />
              )}

              <Paper>
                {this.state.currentTab === "calendar" ? (
                  <div className="scheduleWrapper">
                    <Scheduler data={filteredSessions}>
                      <ViewState
                        currentDate={currentDate}
                        currentViewName={"Day"}
                      />

                      {!conference.scheduleFilters.onlyMy && (
                        <GroupingState
                          grouping={grouping}
                          groupByDate={() => {
                            return true
                          }}
                        />
                      )}

                      <DayView
                        timeTableCellComponent={TimeTableCell}
                        cellDuration={60}
                        startDayHour={0}
                        endDayHour={24}
                      />

                      <Appointments appointmentComponent={this.myAppointment} />
                      {!conference.scheduleFilters.onlyMy && (
                        <Resources data={resources} mainResourceName="roomId" />
                      )}
                      {!conference.scheduleFilters.onlyMy && (
                        <IntegratedGrouping />
                      )}
                      {!conference.scheduleFilters.onlyMy && <GroupingPanel />}
                      <CurrentTimeIndicator shadePreviousCells={true} />
                    </Scheduler>
                  </div>
                ) : (
                  <ConferenceEventList
                    handleListClick={this.handleListClick}
                    conference={conference}
                    filteredSessions={filteredSessions}
                    loadConferenceEvent={this.loadConferenceEvent}
                  />
                )}
              </Paper>
              {activeEvent.activeSession && (
                <SessionTooltip
                  formattedEvents={formattedEvents}
                  appointmentData={activeEvent.activeSession}
                  onClose={() => this.props.setActiveSession(null)}
                />
              )}
            </div>
          </div>
        </div>
      </>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ConferenceSchedule)
