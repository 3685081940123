import { useState, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"

import { changeNavbar } from "../../actions"
import { RootState } from "../../store/store"

import Loading from "@mobilemind/common/src/components/Loading"

import moment from "moment"
import theme from "@mobilemind/common/src/theme/theme"
import { getMandatedTraining } from "./mandatedTrainingSlice"
import { createUserRCS } from "@mobilemind/common/src/actions/mandatedTraining"

import { useParams } from "react-router-dom"

import { CircularProgressbarWithChildren } from "react-circular-progressbar"
import "react-circular-progressbar/dist/styles.css"

import "./mandated-training.scss"

import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Box,
  Tab,
  TextField,
} from "@mui/material"
import { TabsCapsule } from "@mobilemind/common/src/components/tabsCapsule/TabsCapsule"

import _ from "lodash"
import ButtonSmall from "../../components/ButtonSmall"
import classNames from "classnames"
import LearningBuddyMessage from "@mobilemind/common/src/components/LearningBuddyMessage"
import CertificateMenu from "@mobilemind/common/src/components/backpack/certificate/CertificateMenu"
import { fetchMandatedTrainingHistory } from "@mobilemind/common/src/actions/backpack"

import {
  RCSExploreItem,
  RCSExploreCourseItem,
} from "@mobilemind/common/src/types/mandatedTraining"

import {
  DrupalViewPager,
  DrupalViewEmptyRows,
  ReactRouterHistory,
} from "@mobilemind/common/src/types/utilities"

import { MandatedTrainingHistory } from "@mobilemind/common/src/types/mandatedTraining"
import MandatedTrainingCourseList from "./MandatedTrainingCourseList"
import { buddyDialogue } from "@mobilemind/common/src/functions/learningBuddy"

type LayoutProps = {
  history: ReactRouterHistory
}

type HistoryResponse = {
  type: "backpack/fetchMandatedTrainingHistory"
  payload: {
    rows: MandatedTrainingHistory[] | DrupalViewEmptyRows
    pager: DrupalViewPager
  }
}

/**
 * Layout component for the Mandated Training feature.
 * @param history - The history object from React Router.
 */
export function MandatedTrainingLayout({ history }: LayoutProps) {
  // First establish the trainingId from the URL
  const params: { trainingId: string } = useParams()
  const trainingId = params.trainingId

  // Get what we need from the store
  const { session, mandatedTraining } = useSelector((state: RootState) => state)

  // Identify the active training series
  const activeTrainingSeries = mandatedTraining.data.find(
    (series: RCSExploreItem) => {
      return Number(series.rcs_id) === Number(trainingId)
    }
  )

  const dispatch = useDispatch()
  const [buddyMessage, setBuddyMessage] = useState<string>("")

  const [fetched, setFetched] = useState(false)
  const [historyFetched, setHistoryFetched] = useState<boolean>(false)

  const [archiveHistory, setArchiveHistory] = useState<
    MandatedTrainingHistory[]
  >([])
  const [search, setSearch] = useState("")

  const [status, setStatus] = useState("all")
  const [currentTab, setCurrentTab] = useState<string>("thisYear")
  const [isCreatingURCS, setIsCreatingURCS] = useState(false)

  // useEffect dispatches the changeNavbar action, getMandatedTraining
  // and fetchMandatedTrainingHistory if not fetched yet
  useEffect(() => {
    dispatch(changeNavbar("learn"))

    if (!fetched) {
      dispatch(getMandatedTraining())
      setFetched(true)
    }
    if (!buddyMessage) {
      const msgIndex = Math.floor(
        Math.random() * Math.floor(buddyDialogue.congratulatory.length - 1)
      )
      setBuddyMessage(buddyDialogue.congratulatory[msgIndex])
    }

    if (
      !historyFetched &&
      activeTrainingSeries &&
      activeTrainingSeries.urcs_archive_data &&
      activeTrainingSeries.urcs_archive_data.length > 0
    ) {
      dispatch(
        fetchMandatedTrainingHistory(
          { dateRange: "all" },
          null,
          trainingId,
          true
        )
      ).then((response: HistoryResponse) => {
        if (response && response.payload.rows) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          setArchiveHistory(response.payload.rows)
        } else {
          setHistoryFetched(true)
        }
      })
    }
  }, [
    fetched,
    dispatch,
    trainingId,
    activeTrainingSeries,
    buddyMessage,
    historyFetched,
  ])

  const courses = activeTrainingSeries ? activeTrainingSeries.rcs_courses : []
  const hasOpened = activeTrainingSeries
    ? moment().isAfter(moment(activeTrainingSeries.open_date))
    : false

  // Create a new User RCS entity when clicking the "Start" button
  async function createURCS() {
    // Trigger the loading state
    setIsCreatingURCS(true)
    // Create and refetch
    await dispatch(createUserRCS({ series: activeTrainingSeries }))
    await dispatch(getMandatedTraining())
    // Turn off loading state
    setIsCreatingURCS(false)
  }

  if (activeTrainingSeries) {
    // Determine the days left until the deadline
    let daysLeft = moment(activeTrainingSeries.deadline_date).diff(
      moment(),
      "days"
    )

    // Filter our courses by search and status selections
    const filteredBySearch = courses.filter((course: RCSExploreCourseItem) => {
      return course.name.toLowerCase().includes(search.toLowerCase())
    })

    const filteredByStatus = filteredBySearch.filter(
      (course: RCSExploreCourseItem) => {
        if (status === "out-of-compliance") {
          return course.in_compliance === false
        } else if (status === "completed") {
          return course.in_compliance === true
        }
        return course
      }
    )

    // Always put the courses in order of compliance
    const orderedCourses = _.orderBy(
      filteredByStatus,
      ["in_compliance"],
      ["asc"]
    )

    // Determine if all courses are compliant
    let isAllCompliant =
      !courses.filter((course: RCSExploreCourseItem) => !course.in_compliance)
        .length && moment().isAfter(moment(activeTrainingSeries.open_date))

    return (
      <div className="page mandated-course-layout">
        <div className="inner">
          <div
            className="status-panel"
            style={{
              backgroundPosition: "center",
              backgroundImage: "url(" + activeTrainingSeries.rcs_image + ")",
            }}
          >
            <div className="backButton">
              <ButtonSmall
                onClick={() => {
                  history.length > 2 ? history.goBack() : history.push("/", {})
                }}
              />
            </div>

            {hasOpened && (
              <>
                {isAllCompliant ? (
                  <>
                    <span className="status-complete icon check" />
                  </>
                ) : (
                  <>
                    {daysLeft >= 1 ? (
                      <div className="deadline">{daysLeft} Days Left</div>
                    ) : (
                      <div className="deadline">Compliance Overdue</div>
                    )}
                  </>
                )}
              </>
            )}

            <div className="text-container">
              <h2
                style={{
                  marginBottom: isAllCompliant ? 20 : 0,
                }}
              >
                {activeTrainingSeries.name}
              </h2>
              {!isAllCompliant && (
                <span className="date-range">
                  {" "}
                  {moment(moment(activeTrainingSeries.open_date)).format(
                    "MMMM Do"
                  )}{" "}
                  —{" "}
                  {moment(moment(activeTrainingSeries.deadline_date)).format(
                    "MMMM Do"
                  )}
                </span>
              )}
            </div>

            {/**
              If they're compliant for the year, show the Learning Buddy message, otherwise show the progress circle
              Unless they haven't started yet, in which case show the "Start" button
            **/}

            {activeTrainingSeries.urcs_data.urcs_id ? (
              <>
                {isAllCompliant ? (
                  <div className="learning-buddy-success">
                    <LearningBuddyMessage
                      message={
                        <>
                          <div>
                            <strong>{buddyMessage}</strong> You've completed
                            this mandated training.
                          </div>
                          <CertificateMenu
                            user={session.user}
                            label={"View Certificate"}
                            training={archiveHistory[0]}
                            hideShare={true}
                          />
                        </>
                      }
                    />
                  </div>
                ) : (
                  <>
                    {hasOpened && (
                      <div className="progress-circle">
                        <CircularProgressbarWithChildren
                          strokeWidth={10}
                          value={activeTrainingSeries.percentComplete}
                        >
                          <span className="progress-text">
                            {activeTrainingSeries.percentComplete}
                          </span>
                        </CircularProgressbarWithChildren>
                      </div>
                    )}
                  </>
                )}
              </>
            ) : (
              <>
                {isCreatingURCS ? (
                  <div>
                    <Loading />
                  </div>
                ) : (
                  <>
                    {hasOpened && (
                      <div className="start-now progress-circle">
                        <Button
                          sx={{
                            backgroundColor: theme.palette.secondary.main,

                            "&:hover": {
                              backgroundColor: theme.palette.secondary.light,
                            },
                          }}
                          onClick={() => createURCS()}
                        >
                          Start
                        </Button>
                      </div>
                    )}
                  </>
                )}
              </>
            )}
            {!hasOpened && (
              <div className="learning-buddy-success" style={{ bottom: -30 }}>
                <LearningBuddyMessage
                  message={
                    <>
                      <div>
                        <strong>Sit tight!</strong> This mandated training opens{" "}
                        {moment(activeTrainingSeries.open_date).fromNow()}.
                      </div>
                    </>
                  }
                />
              </div>
            )}
          </div>

          <div
            className={classNames(
              "courses-panel",
              (!activeTrainingSeries.urcs_data.urcs_id || !hasOpened) &&
                "disabled"
            )}
          >
            {archiveHistory && archiveHistory.length > 0 && (
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <TabsCapsule
                  value={currentTab}
                  onChange={(event, newValue) => setCurrentTab(newValue)}
                  sx={{ mt: isAllCompliant ? 7 : 11 }}
                >
                  <Tab label="This Year" value="thisYear" />
                  <Tab label="History" value="history" />
                </TabsCapsule>
              </Box>
            )}

            <div
              style={{
                padding: 15,
                paddingBottom: 0,
                paddingTop:
                  archiveHistory && archiveHistory.length > 0 ? 10 : 50,
              }}
            >
              <p style={{ margin: 0 }}>
                {activeTrainingSeries.field_description}
              </p>
            </div>

            {currentTab === "thisYear" && (
              <div className="mandated-filters">
                <TextField
                  style={{ flex: 1, marginRight: 25 }}
                  label="Search for courses"
                  variant="outlined"
                  value={search}
                  onChange={(event) => setSearch(event.target.value)}
                />

                <FormControl sx={{ width: 200 }} className="status-select">
                  <InputLabel>Status</InputLabel>

                  <Select
                    value={status}
                    onChange={(event) => setStatus(event.target.value)}
                  >
                    <MenuItem value={"all"}>All</MenuItem>
                    <MenuItem value={"out-of-compliance"}>Incomplete</MenuItem>
                    <MenuItem value={"completed"}>Completed</MenuItem>
                  </Select>
                </FormControl>
              </div>
            )}

            {/** On the default "This Year" tab, just show the course list **/}
            {currentTab === "thisYear" && (
              <MandatedTrainingCourseList
                trainingId={Number(trainingId)}
                orderedCourses={orderedCourses}
                activeTrainingSeries={activeTrainingSeries}
              />
            )}

            {/** Show a list of all RCS archive items and render the CertificateMenu component **/}
            {currentTab === "history" && (
              <ul
                style={{ marginTop: 20 }}
                className="mandated-training-history"
              >
                {archiveHistory.map((archive) => {
                  return (
                    <li key={archive.umta_id}>
                      <span
                        className="icon check"
                        style={{ marginRight: 10 }}
                      />
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          flex: 1,
                        }}
                      >
                        <strong>Completed</strong>
                        <span
                          style={{
                            fontWeight: "bold",
                            fontSize: 12,
                          }}
                        >
                          {moment(archive.field_comp_date).format(
                            "MMMM Do, YYYY"
                          )}
                        </span>
                      </div>
                      <CertificateMenu
                        user={session.user}
                        label={"View Certificate"}
                        training={archive}
                        hideShare={true}
                      />
                    </li>
                  )
                })}
              </ul>
            )}
          </div>
        </div>
      </div>
    )
  }

  return (
    <Loading
      fullPage={true}
      message={"Getting mandated training..."}
      theme={theme}
    />
  )
}
