import { useDispatch, useSelector } from "react-redux"
import classNames from "classnames"
import {
  addOutlookEventIdToUserEvent,
  updateRSVP,
} from "../../../actions/index"
import { addToGoogleCalendar } from "../../../actions/emailIntegration"
import { Menu, MenuItem } from "@mui/material"
import { RootState } from "../../../store/store"
import { UserEvent } from "../types"
import { PublicClientApplication, InteractionType } from "@azure/msal-browser"
import { loginRequest } from "../../../graphAuthConfig"
import { useMsal } from "@azure/msal-react"
import { AuthCodeMSALBrowserAuthenticationProvider } from "@microsoft/microsoft-graph-client/authProviders/authCodeMsalBrowser"
import { Client } from "@microsoft/microsoft-graph-client"
import moment from "moment"
import { Event } from "@microsoft/microsoft-graph-types"

type MenuProps = {
  anchorEl: any
  rsvpOpen: boolean
  hasRSVPd: string
  handleClose: () => void
  userEvent: UserEvent
}

export function RSVPMenu({
  anchorEl,
  rsvpOpen,
  hasRSVPd,
  handleClose,
  userEvent,
}: MenuProps) {
  const { activeEvent, session } = useSelector((state: RootState) => state)

  const dispatch = useDispatch()
  const msal = useMsal()

  const integrationSettings =
    session.user.attributes.field_calendar_integration ?? []

  const handleRSVPClick = async (rsvp: string) => {
    handleClose()

    const { field_google_calendar_event_id, field_outlook_event_id } =
      userEvent.attributes

    await dispatch(
      updateRSVP({ appointmentData: null, userEvent, rsvp, activeEvent })
    )
    if (rsvp === "accept") {
      // If they've added Google integration and haven't added the event already
      if (
        integrationSettings.includes("google") &&
        !field_google_calendar_event_id
      ) {
        dispatch(addToGoogleCalendar(activeEvent, userEvent))
      }
      // If they've added Outlook integration and haven't added the event already
      if (integrationSettings.includes("outlook") && !field_outlook_event_id) {
        let loginResponse
        try {
          loginResponse = await msal.instance.acquireTokenSilent({
            scopes: loginRequest.scopes,
            prompt: "select_account",
          })
        } catch (err) {
          try {
            loginResponse = await msal.instance.loginPopup({
              scopes: loginRequest.scopes,
              prompt: "select_account",
            })
          } catch (err) {
            console.log("There was a problem launching Microsoft Sign In.", err)
          }
        }

        loginResponse && msal.instance.setActiveAccount(loginResponse.account)

        const authProvider = new AuthCodeMSALBrowserAuthenticationProvider(
          msal.instance as PublicClientApplication,
          {
            account: msal.instance.getActiveAccount()!,
            scopes: loginRequest.scopes,
            interactionType: InteractionType.Popup,
          }
        )

        const client = Client.initWithMiddleware({
          authProvider: authProvider,
        })

        const urlType =
          activeEvent.bundle === "event_base" ? "event" : "conference"

        // @todo construct our event body
        const newEvent: Event = {
          subject: activeEvent.name,
          start: {
            dateTime: moment(activeEvent.startDate).format(
              "MM/DD/YYYY hh:mm A"
            ),
            timeZone:
              session.user.attributes.timezone ?? "Eastern Standard Time",
          },
          end: {
            dateTime: moment(activeEvent.endDate).format("MM/DD/YYYY hh:mm A"),
            timeZone:
              session.user.attributes.timezone ?? "Eastern Standard Time",
          },
          isAllDay: activeEvent.allDay,
          body: {
            contentType: "html",
            content:
              activeEvent.description +
              "<br/>" +
              '<p><em>For more information, <a href="' +
              process.env.REACT_APP_TEACHER_URL +
              "/events/" +
              urlType +
              "/" +
              activeEvent.drupal_internal__id +
              '"><strong>visit the event page on MobileMind.</strong></a></em></p>',
          },
        }

        const field_address = activeEvent.location?.attributes.field_address

        if (field_address) {
          const {
            address_line1,
            locality,
            postal_code,
            country_code,
            administrative_area,
          } = field_address

          newEvent.location = {
            displayName: activeEvent.location.attributes.name,
            address: {
              city: locality,
              countryOrRegion: country_code,
              postalCode: postal_code,
              state: administrative_area,
              street: address_line1,
            },
          }
        }

        let outlookResponse = await client.api("/me/events").post(newEvent)

        if (outlookResponse) {
          dispatch(
            addOutlookEventIdToUserEvent({
              activeEvent,
              userEvent,
              outlookId: outlookResponse.id,
            })
          )
        }
      }
    }
  }

  return (
    <>
      <Menu
        id="rsvp-menu"
        anchorEl={anchorEl}
        open={rsvpOpen}
        className={classNames(hasRSVPd && "hasRSVPd")}
        onClose={() => handleClose()}
      >
        {((!activeEvent.atCapacity &&
          (activeEvent.totalAttendees < activeEvent.sessionMaxCapacity ||
            !activeEvent.sessionMaxCapacity)) ||
          hasRSVPd === "accept") && (
          <MenuItem
            className={classNames(
              userEvent &&
                userEvent.attributes.field_rsvp === "accept" &&
                "active"
            )}
            onClick={() => {
              handleRSVPClick("accept")
            }}
          >
            Accept
          </MenuItem>
        )}
        <MenuItem
          className={classNames(
            userEvent &&
              userEvent.attributes.field_rsvp === "tentative" &&
              "active"
          )}
          onClick={() => {
            handleRSVPClick("tentative")
          }}
        >
          Tentative
        </MenuItem>
        <MenuItem
          className={classNames(
            userEvent &&
              userEvent.attributes.field_rsvp === "decline" &&
              "active"
          )}
          onClick={() => {
            handleRSVPClick("decline")
          }}
        >
          Decline
        </MenuItem>
      </Menu>
    </>
  )
}
