import { combineReducers } from "redux"

import produce from "immer"
import explore from "../features/explore/exploreSlice"
import learningPaths from "../features/learningPaths/learningPathsSlice"
import mandatedTraining from "../features/mandatedTraining/mandatedTrainingSlice"
import leaderboards from "../features/leaderboards/leaderboardsSlice"
import goals from "../features/goals/goalsSlice"

import locations from "../features/locations/locationsSlice.js"

import rewind from "../features/rewind/rewindSlice"
import activeEvent from "../features/events/eventSingle/activeEventSlice"
import dashboard from "../features/dashboard/dashboardSlice"
import badges from "../features/badges/badgesSlice"
import achievements from "../features/achievements/achievementsSlice"

import inProgress from "../features/inProgress/inProgressSlice"

// New typescript fancy slices
import categories from "./reducers/categories"
import backpack from "./reducers/backpack"
import calendar from "./reducers/calendar"
import externalPD from "./reducers/externalPD"
import navbar from "./reducers/navbar"
import learningBuddies from "./reducers/learningBuddies"
import session from "./reducers/session"
import tags from "./reducers/tags"

const extension = produce((draft = {}, action) => {
  switch (action.type) {
    case "extension/getVersion":
      draft.version = action.payload
      break
    default:
      return draft
  }
})

const notifications = produce((draft = { data: [] }, action) => {
  switch (action.type) {
    case "notifications/get":
      draft.data = action.payload
      break
    default:
      return draft
  }
})

const courses = produce((draft = { courseData: [] }, action) => {
  switch (action.type) {
    case "courses/getQuiz":
      let targetCourse = draft.courseData.find(
        (course) => course.id === action.meta.courseId
      )
      if (targetCourse) {
        targetCourse.quizEntity = action.payload
      }
      break

    case "courses/get":
      action.payload &&
        action.payload.forEach((course) => {
          if (
            action.meta &&
            action.meta.quiz &&
            course.relationships.field_quiz.data
          ) {
            course.quiz = action.meta.quiz
          }

          if (
            action.meta &&
            action.meta.field_mul &&
            action.meta.field_mul.length
          ) {
            course.field_mul = action.meta.field_mul
          }

          if (action.meta && action.meta.sourceTemplate) {
            course.sourceTemplate = action.meta.sourceTemplate
          }

          if (action.meta && action.meta.attachments) {
            course.attachments = action.meta.attachments
          }

          let relatedTags =
            course.relationships.field_tags &&
            course.relationships.field_tags.data

          if (relatedTags) {
            course.tags =
              action.meta && action.meta.tags
                ? action.meta.tags.filter((tag) => {
                    return relatedTags.find((related) => related.id === tag.id)
                  })
                : []
          }

          // Don't merge in duplicates
          if (!draft.courseData.find((existing) => existing.id === course.id)) {
            // Make sure the course has a category
            if (course.relationships.field_category.data) {
              draft.courseData.push(course)
            }
          } else {
            let targetCourse = draft.courseData.find(
              (target) => target.id === course.id
            )
            if (action.meta && action.meta.quiz) {
              targetCourse.quiz = action.meta.quiz
            }
            if (action.meta && action.meta.attachments) {
              targetCourse.attachments = action.meta.attachments
            }
            if (action.meta && action.meta.sourceTemplate) {
              targetCourse.sourceTemplate = action.meta.sourceTemplate
            }
          }
        })

      if (action.meta && action.meta.fetched) {
        draft.fetched = action.meta.fetched
      }
      break

    default:
      return draft
  }
})

const sidebar = produce((draft = {}, action) => {
  switch (action.type) {
    case "sidebar/collapse":
      draft.isCollapsed = true
      break
    case "sidebar/peek":
      draft.isPeeked = action.payload
      break
    case "sidebar/show":
      draft.isCollapsed = false
      break
    case "sidebar/select":
      draft.pages[action.payload].activeItem = action.meta.activeItem
      break
    case "sidebar/expandMobileFilters":
      draft.mobileFiltersExpanded = action.payload
      break
    case "sidebar/updateExploreFilters":
      draft.pages.learn.items.find((item) => item.id === "explore").filters =
        action.payload
      break
    case "sidebar/updateLearningPathFilters":
      draft.pages.learn.items.find(
        (item) => item.id === "learningPaths"
      ).filters = action.payload
      break
    case "sidebar/updateBadgeFilters":
      draft.pages.learn.items.find((item) => item.id === "badges").filters =
        action.payload
      break
    case "sidebar/updateBadgesEarnedFilters":
      draft.pages.achievements.items.find(
        (item) => item.id === "badgesEarned"
      ).filters = action.payload
      break
    case "sidebar/updateCompletedFilters":
      draft.pages.achievements.items.find(
        (item) => item.id === "completed"
      ).filters = action.payload
      break
    default:
      return draft
  }
})

const assessments = produce(
  (
    draft = {
      fetched: false,
      all: [],
      saved_for_later: [],
      in_progress: [],
      submitted: [],
      completed: [],
    },
    action
  ) => {
    switch (action.type) {
      case "assessments/delete":
        draft.all = draft.all.filter(
          (assessment) => assessment.id !== action.payload
        )
        draft.in_progress = draft.in_progress.filter(
          (assessment) => assessment.id !== action.payload
        )
        break

      case "assessments/getRevisions":
        let targetAssessment = draft.all.find(
          (assessment) =>
            assessment.attributes.drupal_internal__id ===
            action.meta.assessmentId
        )

        targetAssessment.revisions = action.payload

        break

      case "assessments/startFromSaved":
        draft.saved_for_later = draft.saved_for_later.filter(
          (assessment) => assessment.id !== action.payload.id
        )
        draft.in_progress.push(action.payload)
        break

      case "assessments/create":
        draft[action.meta.status].push(action.payload)
        draft.all.push(action.payload)
        break

      case "assessments/get":
        draft.fetched = action.meta.fetched

        if (action.meta && action.meta.status) {
          action.payload.forEach((assessment) => {
            if (
              !draft[action.meta.status].some(
                (existing) => existing.id === assessment.id
              )
            ) {
              draft[action.meta.status].push(assessment)
            }
          })

          action.payload.forEach((assessment) => {
            if (
              !draft[action.meta.status].some(
                (existing) => existing.id === assessment.id
              )
            ) {
              draft.all.push(assessment)
            }
          })
        } else {
          // Don't merge in duplicates
          action.payload.forEach((assessment) => {
            let status

            if (action.meta && action.meta.included) {
              const quizAnswers = action.meta.included.filter(
                (included) => included.type === "paragraph--quiz_submission"
              )

              if (quizAnswers) {
                assessment.quizAnswers = quizAnswers.filter(
                  (answer) =>
                    Number(answer.attributes.parent_id) ===
                    assessment.attributes.drupal_internal__id
                )
                draft.all.push(assessment)
              }
            }

            if (assessment.attributes.field_status === "grading_in_progress") {
              status = "submitted"
            } else {
              status = assessment.attributes.field_status
            }

            if (
              draft[status] &&
              !draft[status].some((existing) => existing.id === assessment.id)
            ) {
              draft[status].push(assessment)
            }
            if (!draft.all.some((existing) => existing.id === assessment.id)) {
              draft.all.push(assessment)
            }
          })
        }
        break

      default:
        return draft
    }
  }
)

const jobTitles = produce((draft = { data: [], fetched: false }, action) => {
  switch (action.type) {
    case "jobTitles/get":
      draft.data = action.payload
      draft.fetched = true
      break
    default:
      return draft
  }
})

const announcements = produce(
  (draft = { data: [], media: [], surveys: [] }, action) => {
    switch (action.type) {
      case "announcements/get":
        if (action.meta.included && !action.meta.included.content) {
          draft.data = action.payload
          draft.learningPaths = action.meta.included.filter(
            (included) => included.field_prerequisite_learning_path
          )
          draft.courses = action.meta.included.filter(
            (included) => included.field_automatic_review
          )
          draft.images = action.meta.included.filter(
            (included) =>
              included.type && included.type[0].target_id === "image"
          )
          draft.videos = action.meta.included.filter(
            (included) =>
              included.type && included.type[0].target_id === "video"
          )
          draft.surveys = action.meta.included.filter(
            (included) =>
              included.label && included.label === "Net Promoter Score"
          )
          draft.types = action.meta.included.filter(
            (included) =>
              included.vid && included.vid[0].target_id === "announcement_type"
          )
        }
        break

      default:
        return draft
    }
  }
)

const viewStyle = (state = "grid", action) => {
  switch (action.type) {
    case "viewStyle/select":
      return action.payload
    default:
      return state
  }
}

const productTour = produce((draft = { isStarted: false }, action) => {
  switch (action.type) {
    case "productTour/start":
      draft.isStarted = true
      draft.trigger = action.meta.trigger
      break
    case "productTour/end":
      draft.isStarted = false
      draft.hasStarted = true
      break
    default:
      return draft
  }
})

const onboarding = produce((draft = { started: false }, action) => {
  switch (action.type) {
    case "onboarding/start":
      draft.started = true
      break
    case "onboarding/end":
      draft.started = false
      break
    default:
      return draft
  }
})

const reducers = combineReducers({
  // Typescript slices
  categories,
  backpack,
  calendar,
  externalPD,
  learningBuddies,
  navbar,
  session,
  tags,

  // Features slices or immer reducers
  explore,
  inProgress,
  rewind,
  activeEvent,
  dashboard,
  leaderboards,
  goals,
  achievements,
  extension,
  courses,
  learningPaths,
  mandatedTraining,
  badges,
  jobTitles,
  assessments,
  sidebar,
  announcements,
  viewStyle,
  productTour,
  onboarding,
  notifications,
  locations,
})

export default reducers
